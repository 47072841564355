/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './Variant.module.scss'

const Variant = ({
  answers,
  isAnswered,
  selectedAnswer,
  handleAnswer,
  answersJson,
}) => {
  if (answersJson) {
    return Object.keys(answersJson).map((answer, key) => (
      <label
        key={key}
        answer={answer}
        className={classNames(
          styles.answerLabel,
          isAnswered && answersJson[answer] ? styles.success : null,
        )}
      >
        <input
          key={key}
          hidden
          type="radio"
          checked={selectedAnswer === key}
          onChange={() => handleAnswer(key, answer)}
          disabled={isAnswered}
        />
        {isAnswered && !answersJson[answer] ? <s>{answer}</s> : answer}
      </label>
    ))
  }
  return answers.map((answer, key) => (
    <label
      key={key}
      answer={answer}
      className={classNames(
        styles.answerLabel,
        isAnswered && answer.correct ? styles.success : null,
      )}
    >
      <input
        key={key}
        hidden
        type="radio"
        checked={selectedAnswer === key}
        onChange={() => handleAnswer(key)}
        disabled={isAnswered}
      />
      {isAnswered && !answer.correct ? <s>{answer.value}</s> : answer.value}
    </label>
  ))
}

Variant.propTypes = {
  // answers: PropTypes.shape([]).isRequired,
  isAnswered: PropTypes.bool.isRequired,
  selectedAnswer: PropTypes.bool.isRequired,
}

export default Variant
