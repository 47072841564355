/* eslint-disable react/no-danger */
import React from 'react'
import startCase from 'lodash-es/startCase'
import { ChevronLeft } from 'react-feather'
import { Script } from 'gatsby'
import replaceText from '../../utils/replaceText'
import './styles.css'

export default (props) => {
  const { article, subcategory, site, jobsLinks, tokens } = props
  if (article) {
    return (
      <nav className="breadcrumb-nav">
        <ol className="breadcrumb m-0" id="breadcrumblist">
          <li className="breadcrumb-item">
            <a href={`/${article.category.category.slug}`}>
              <span>{article.category.category.title}</span>
            </a>
          </li>
          <li className="breadcrumb-item active">
            <a
              href={`/${article.category.category.slug}/${article.category.slug}`}
            >
              <span>{article.category.title}</span>
            </a>
          </li>
          <Script
            defer
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `
            ${JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": `${process.env.GATSBY_SITE_BASE_URL}/`
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": `${article.category.category.title}`,
                    "item": `${process.env.GATSBY_SITE_BASE_URL}/${article.category.category.slug}/`
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": `${article.category.title}`,
                    "item": `${process.env.GATSBY_SITE_BASE_URL}/${article.category.category.slug}/${article.category.slug}/`
                  },
                  {
                    "@type": "ListItem",
                    "position": 4,
                    "name": `${replaceText(tokens, article.title)}`,
                    "item": `${process.env.GATSBY_SITE_BASE_URL}/${article.category.category.slug}/${article.category.slug}/${article.slug}`
                  }
                ]
              })}`,
            }}
          />
        </ol>
      </nav>
    )
  }
  if (subcategory) {
    return (
      <ol className="breadcrumb m-0" id="breadcrumblist">
        <li className="breadcrumb-item">
          <span className="d-flex align-items-center">
            <ChevronLeft /> {subcategory.category.title}
          </span>
        </li>
        <Script
          defer
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            ${JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": `${process.env.GATSBY_SITE_BASE_URL}/`
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": `${subcategory.category.title}`,
                  "item": `${process.env.GATSBY_SITE_BASE_URL}/${subcategory.category.slug}/`
                },
                {
                  "@type": "ListItem",
                  "position": 3,
                  "name": `${subcategory.title}`,
                  "item": `${process.env.GATSBY_SITE_BASE_URL}/${subcategory.category.slug}/${subcategory.slug}/`
                },
              ]
            })}`,
          }}
        />
      </ol>
    )
  }
  if (jobsLinks) {
    return (
      <nav>
        <ol className="jobs-breadcrumb breadcrumb m-0" id="breadcrumblist">
          <li className="breadcrumb-item">
            <a href="/jobs">
              <span>Jobs</span>
            </a>
          </li>
          <li className="breadcrumb-item active">
            <a href={`/jobs/${jobsLinks.slug}`}>
              <span>
                {startCase(
                  jobsLinks.location ? jobsLinks.location : jobsLinks.keyword,
                )}
              </span>
            </a>
          </li>

          <Script
            defer
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `
            ${JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: [
                  {
                    '@type': 'ListItem',
                    position: 1,
                    item: {
                      '@id': `jobs`,
                      name: `Jobs`,
                    },
                  },
                  {
                    '@type': 'ListItem',
                    position: 2,
                    item: {
                      '@id': `/jobs/${jobsLinks.slug}`,
                      name: `${startCase(
                        jobsLinks.location
                          ? jobsLinks.location
                          : jobsLinks.keyword,
                      )}`,
                    },
                  },
                ],
              })}
  `,
            }}
          />
        </ol>
      </nav>
    )
  }
  return null
}
