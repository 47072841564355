import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Col, Row } from '../../../lib'
import './Product.css'
import cleanText from '../../../utils/cleanText'
import replaceText from '../../../utils/replaceText'

const AMAZON_UK_TAG = 'chriscentra0d-21'
const AMAZON_COM_TAG = 'wikijob0b-20'

const Product = ({ product,tokens }) => {
  const {
    label,
    buttonUrl,
    buttonText,
    name,
    image,
    subheading,
    bodyString: body,
  } = product

  const handleAmazonLink = (url) => {
    if (
      buttonUrl.includes('amazon.co.uk') ||
      buttonUrl.includes('amazon.com')
    ) {
      const modifier = buttonUrl.includes('?') ? '&' : '?'
      const newUrl = `${buttonUrl}${modifier}tag=${
        buttonUrl.includes('amazon.co.uk') ? AMAZON_UK_TAG : AMAZON_COM_TAG
      }`
      return newUrl
    }

    return url
  }

  return (
    <div
      id={cleanText(name)}
      className="product border rounded mt-5 mb-5 p-4 shadow mv-skip"
    >
      <div
        id={cleanText(subheading)}
        className="ps-2 rounded text-center product-title"
      >
        {label}
      </div>
      <Row>
        <Col sm={6}>
          <a
            href={handleAmazonLink(buttonUrl)}
            rel="sponsored nofollow noreferrer"
            target="_blank"
            className="productLink"
          >
            {image.gatsbyImageData && (
              <GatsbyImage
                className="mt-3 img-fluid rounded-3"
                alt={replaceText(tokens,name)}
                image={image.gatsbyImageData}
                loading="lazy"
              />
            )}
          </a>
        </Col>
        <Col sm={6}>
          <a
            href={handleAmazonLink(buttonUrl)}
            rel="sponsored nofollow noreferrer"
            target="_blank"
            className="productLink"
          >
            <h2 style={{ border: 'none' }}>{name}</h2>
          </a>
          {subheading ? (
            <h3 style={{ fontSize: '1.2em' }}>{subheading}</h3>
          ) : null}
          <p>{body}</p>
          <a
            className="btn btn-orange btn-lg d-block productLink"
            href={handleAmazonLink(buttonUrl)}
            target="_blank"
            rel="sponsored nofollow noreferrer"
          >
            <strong>{buttonText}</strong>
          </a>
        </Col>
      </Row>
    </div>
  )
}

export default Product