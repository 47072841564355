import React from 'react'
import PropTypes from 'prop-types'
import styles from './Explanation.module.scss'

const Explanation = ({ value }) => (
  <div className={styles.questionExplanation}>
    <hr />

    <div dangerouslySetInnerHTML={{ __html: value }} />
  </div>
)

Explanation.propTypes = {
  value: PropTypes.string.isRequired,
}

export default Explanation
