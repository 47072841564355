import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import {
  Form,
  Label,
  Input,
  Modal,
  Button,
  FormGroup,
  ModalBody,
  ModalHeader,
} from '../../../common/lib'

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

const DialogBox = ({ title = '', message = '', open, onClose }) => {
  const location = useLocation()
  const [submit, setSubmit] = useState(false)
  const [state, setState] = useState({
    review: '',
    email: '',
    url: location.href,
  })

  const handleSubmit = async (e) => {
    if (!state?.review) return
    const formdata = new FormData(document.getElementById('articleFeed'))

    try {
      const response = await fetch('/', {
        method: 'POST',
        body: formdata,
      })

      if (response.status !== 200) {
        throw new Error('Try Again Later')
      }

      window.dataLayer.push({
        event: 'feedback',
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      className="feedback-modal"
      centered
      isOpen={open}
      toggle={() => {
        onClose()
        setSubmit(false)
      }}
      size="lg"
    >
      <ModalHeader
        tag="div"
        style={{
          color: '#171515',
          fontSize: '1.5em',
          fontWeight: '700',
          padding: '10px 15px',
          justifyContent: 'center',
        }}
      >
        {title}
      </ModalHeader>
      <ModalBody>
        {submit ? (
          <h5>{message}</h5>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
              setSubmit(true)
            }}
            data-netlify="true"
            id="articleFeed"
            name="articleFeed"
            netlify-honeypot="bot-field"
            encType="multipart/form-data"
          >
            <input type="hidden" name="form-name" value="articleFeed" />
            <FormGroup>
              <Label for="review">
                What did you like/dislike about this page?{' '}
                <span style={{ color: 'red' }}>*</span>
              </Label>
              <Input
                id="review"
                required
                onChange={(e) => setState({ ...state, review: e.target.value })}
                name="review"
                placeholder="Type here ..."
                type="textarea"
              />
            </FormGroup>
            <FormGroup>
              <Label for="emailfordialogbox">
                Please leave your email address if you'd like us to contact you
                (optional).
              </Label>
              <Input
                id="emailfordialogbox"
                name="email"
                placeholder="Email"
                type="email"
                onChange={(e) => setState({ ...state, email: e.target.value })}
              />
            </FormGroup>
            <FormGroup className="d-none">
              <Input
                id="currentUrl"
                name="currentUrl"
                type="text"
                value={location.href}
              />
            </FormGroup>
            <FormGroup className="float-end">
              <Button>Submit</Button>
            </FormGroup>
          </form>
        )}
      </ModalBody>
    </Modal>
  )
}

export default DialogBox
