import { Script } from 'gatsby'
import * as React from 'react'

const PracticeProblemSchema = ({ questions, title, description }) => {
  const questionArray =
    (questions &&
      questions.map(({ questionNode, answersJson, explanationNode }) => {
        const parsedAnswer = JSON.parse(answersJson)
        const ans = Object.keys(parsedAnswer).map((answer, index) => {
          return {
            '@type': 'Answer',
            position: index,
            encodingFormat: 'text/html',
            text: answer,
          }
        })
        const correctAnswer = Object.keys(parsedAnswer).find((answer) => {
          if (parsedAnswer[answer]) {
            return answer
          }
        })
        const acceptCorrectAns = {
          '@type': 'Answer',
          position: 0,
          encodingFormat: 'text/html',
          text: correctAnswer,
          answerExplanation: {
            '@type': 'Comment',
            encodingFormat: 'text/html',
            text: explanationNode?.childMarkdownRemark?.html,
          },
        }
        return {
          '@type': 'Question',
          eduQuestionType: 'Checkbox',
          learningResourceType: 'Practice problem',
          name: title,
          typicalAgeRange: '18-',
          encodingFormat: 'text/html',
          text: questionNode.childMarkdownRemark.html,
          suggestedAnswer: [...ans],
          acceptedAnswer: [acceptCorrectAns],
        }
      })) ||
    []

  if (!questions?.length) return null

  return (
  
    <Script
    defer
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `${JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'Quiz',
          name: title,
          about: {
            '@type': 'Thing',
            name: description,
          },
          educationalLevel: 'intermediate',
          assesses: title,
          hasPart: [...questionArray],
        })}`,
      }}
    />
  )
}

export default PracticeProblemSchema
