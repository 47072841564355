import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import articleUrl from '../../utils/articleUrl'
import { Row, Col } from '../../lib'
import styles from './subcategoryArticles.module.scss'
import replaceText from '../../utils/replaceText'

const fallbackImage =
  'https://www.datocms-assets.com/7756/1600624248-wikijob-large-2.png?w=220,auto=format,compress'

const formatYear = (s) => s.replace('{YEAR}', new Date().getUTCFullYear())

const byTitleAsc = (a, b) =>
  a.node.title > b.node.title ? 1 : b.node.title > a.node.title ? -1 : 0

const itemImage = (coverImage, title) =>
  coverImage ? (
    <GatsbyImage
      image={coverImage.gatsbyImageData}
      alt={formatYear(title)}
      className={styles.articleCoverImage}
      loading="eager"
      fadeIn={false}
    />
  ) : (
    <div
      className={styles.articleCoverImage}
      style={{
        backgroundImage: `url(${fallbackImage})`,
      }}
    />
  )
const SubCategoryArticles = ({ articles, site, limit = 100, tokens }) => {
  return (
    <Row className={styles.subcategoriesRow}>
      {articles
        .sort(byTitleAsc)
        .slice(0, limit)
        .map(({ node: article }) => {
          return article.slug === '_' ? null : (
            <Col
              xs={12}
              md={6}
              lg={6}
              key={article.id}
              className={styles.subcategoryColumn}
            >
              <a
                className={styles.articleLink}
                href={`${articleUrl(article, site)}`}
              >
                <div className="d-flex justify-content-between w-100">
                  <div style={{ width: '35%' }}>
                    {itemImage(article.coverImage, article.title)}
                  </div>
                  <div style={{ width: '60%' }} className={styles.articleTitle}>
                    {replaceText(tokens, formatYear(article.title))}
                  </div>
                </div>
              </a>
            </Col>
          )
        })}
    </Row>
  )
}

export default SubCategoryArticles
