import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import replaceText from '../../utils/replaceText'

const Image = ({ image,tokens }) => {
  return (
    <>
      {image.image && image.image.gatsbyImageData && (
        <>
          <GatsbyImage
            loading="lazy"
            className="mv-skip img-fluid rounded-3 shadow imageRecord"
            image={image.image.gatsbyImageData}
            alt={replaceText(tokens, image.image.alt)}
          />
          <small>{replaceText(tokens, image.image.title)}</small>
        </>
      )}
    </>
  )
}

export default Image
