import React from 'react'
import {
  EmailIcon,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'

const onClickShare = (network, orientation) => {
  window.dataLayer.push({
    event: 'socialShare',
    network,
    orientation,
  })
}

const onClickShareVert = (net) => () => onClickShare(net, 'vertical')
const onClickShareHor = (net) => () => onClickShare(net, 'horizontal')

export default ({ location }) => {
  const href = `https://www.wikijob.co.uk${location.pathname}`

  return (
    <>
      <div
        className="sharingButtonVertical text-center border rounded"
        style={{
          width: 60,
          position: 'fixed',
          top: '440px',
          left: '100px',
          borderRadius: 7,
          overflow: 'hidden',
          zIndex: 2,
          background: 'white',
        }}
      >
        <FacebookShareButton onClick={onClickShareVert('facebook')} url={href}>
          <FacebookIcon size={58} round={false} />
        </FacebookShareButton>
        <TwitterShareButton
          onClick={onClickShareVert('twitter')}
          url={href}
          via="wikijob"
        >
          <TwitterIcon size={58} round={false} />
        </TwitterShareButton>
        <LinkedinShareButton onClick={onClickShareVert('linkedin')} url={href}>
          <LinkedinIcon size={58} round={false} />
        </LinkedinShareButton>
        <EmailShareButton
          url={href}
          onClick={onClickShareVert('email')}
          subject="Check out this article on WikiJob"
        >
          <EmailIcon size={58} round={false} />
        </EmailShareButton>{' '}
      </div>
      <div className="sharingButtonHorizontal text-center">
        <FacebookShareButton onClick={onClickShareHor('facebook')} url={href}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>{' '}
        <TwitterShareButton
          onClick={onClickShareHor('twitter')}
          url={href}
          via="wikijob"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>{' '}
        <LinkedinShareButton onClick={onClickShareHor('linkedin')} url={href}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>{' '}
        <EmailShareButton
          url={href}
          onClick={onClickShareHor('email')}
          subject="Check out this article on WikiJob"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>{' '}
      </div>
    </>
  )
}
