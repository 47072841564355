import React from 'react'
import LinkIcon from '../Icons/LinkIcon'
import { Link } from 'gatsby'

const HowToStepHeading = ({ element }) => {
  return (
    <h3
      id={element.title.toLowerCase().replaceAll(',', '').replace(/\s/g, '-')}
      style={{ marginBottom: '35px', position: 'relative' }}
    >
      <Link
        className="anchor before"
        aria-label={element.title.toLowerCase()}
        to={`#${element.title.toLowerCase().replaceAll(',', '').replace(/\s/g, '-')}`}
      >
        <LinkIcon />
      </Link>
      Step {element.stepNumber}. {element.title}
    </h3>
  )
}

export default HowToStepHeading
