import React from 'react'

const JobIcon = (props) => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        className="sandro"
        cx="24.0156"
        cy="24.491"
        r="24"
        fill="#FFEAB4"
        fillOpacity="0.6"
      />
      <path
        d="M33.3657 32.5004H13.8874V27.8534C13.3693 27.6053 12.8985 27.2686 12.4961 26.8586V32.5004C12.4961 32.8694 12.6427 33.2233 12.9036 33.4842C13.1645 33.7451 13.5184 33.8917 13.8874 33.8917H33.3657C33.7347 33.8917 34.0885 33.7451 34.3495 33.4842C34.6104 33.2233 34.757 32.8694 34.757 32.5004V26.8586C34.3575 27.2702 33.8859 27.6051 33.3657 27.8465V32.5004Z"
        fill="#E3650C"
      />
      <path
        d="M28.4959 28.6045C28.6804 28.6045 28.8574 28.5312 28.9878 28.4008C29.1183 28.2703 29.1916 28.0934 29.1916 27.9089V24.1106H27.8003V25.5436H20.8438V26.9349H27.8003V27.9089C27.8003 28.0934 27.8736 28.2703 28.004 28.4008C28.1345 28.5312 28.3114 28.6045 28.4959 28.6045Z"
        fill="#E3650C"
      />
      <path
        d="M34.0613 17.1958H27.8004V16.0689C27.7931 15.6236 27.6097 15.1993 27.2902 14.889C26.9708 14.5787 26.5414 14.4076 26.0961 14.4132H21.157C20.7117 14.4076 20.2823 14.5787 19.9628 14.889C19.6434 15.1993 19.4599 15.6236 19.4526 16.0689V17.1958H13.1917C13.0072 17.1958 12.8303 17.2691 12.6998 17.3996C12.5694 17.53 12.4961 17.707 12.4961 17.8915V23.4567C12.4961 24.3792 12.8626 25.2639 13.5149 25.9162C14.1672 26.5685 15.0519 26.935 15.9744 26.935H18.0613V27.9089C18.0613 28.0934 18.1346 28.2703 18.2651 28.4008C18.3955 28.5312 18.5725 28.6045 18.757 28.6045C18.9415 28.6045 19.1184 28.5312 19.2489 28.4008C19.3793 28.2703 19.4526 28.0934 19.4526 27.9089V24.1106H18.0613V25.5437H15.9744C15.4209 25.5437 14.89 25.3238 14.4987 24.9324C14.1073 24.541 13.8874 24.0102 13.8874 23.4567V18.5871H33.3657V23.4567C33.3657 24.0102 33.1458 24.541 32.7544 24.9324C32.363 25.3238 31.8322 25.5437 31.2787 25.5437H30.5831V26.935H31.2787C32.2012 26.935 33.0859 26.5685 33.7382 25.9162C34.3905 25.2639 34.757 24.3792 34.757 23.4567V17.8915C34.757 17.707 34.6837 17.53 34.5532 17.3996C34.4228 17.2691 34.2458 17.1958 34.0613 17.1958ZM26.4091 17.1958H20.8439V16.1037C20.8475 16.0231 20.8821 15.947 20.9404 15.8912C20.9987 15.8355 21.0763 15.8044 21.157 15.8045H26.1031C26.1423 15.8036 26.1814 15.8104 26.2181 15.8246C26.2547 15.8388 26.2882 15.8601 26.3166 15.8872C26.3451 15.9143 26.3679 15.9468 26.3837 15.9827C26.3996 16.0187 26.4082 16.0574 26.4091 16.0967V17.1958Z"
        fill="#E3650C"
      />
    </svg>
  )
}

export default JobIcon
