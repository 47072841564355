import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { mapToCssModules, tagPropType } from '../utils'

const propTypes = {
  light: PropTypes.bool,
  dark: PropTypes.bool,
  full: PropTypes.bool,
  fixed: PropTypes.string,
  sticky: PropTypes.string,
  color: PropTypes.string,
  role: PropTypes.string,
  tag: tagPropType,
  container: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  cssModule: PropTypes.object,
  expand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.node,
}

const defaultProps = {
  tag: 'nav',
  expand: false,
  container: 'fluid',
}

const getExpandClass = (expand) => {
  if (expand === false) {
    return false
  } else if (expand === true || expand === 'xs') {
    return 'navbar-expand'
  }

  return `navbar-expand-${expand}`
}

const Navbar = (props) => {
  const {
    expand,
    className,
    cssModule,
    light,
    dark,
    fixed,
    sticky,
    color,
    container,
    tag: Tag,
    children,
    ...attributes
  } = props

  const classes = mapToCssModules(
    classNames(className, 'navbar', getExpandClass(expand), {
      'navbar-light': light,
      'navbar-dark': dark,
      // [`bg-${color}`]: color,
      [`fixed-${fixed}`]: fixed,
      [`sticky-${sticky}`]: sticky,
    }),
    cssModule,
  )

  const containerClass =
    container && container === true ? 'container' : `container-${container}`

  return (
    <Tag {...attributes} className={classes}>
      {container ? <div className={containerClass}>{children}</div> : children}
    </Tag>
  )
}

Navbar.propTypes = propTypes
Navbar.defaultProps = defaultProps

export default Navbar
