import React from 'react'

const ArticleSource = ({ sources }) => {
  return (
    <>
      <label htmlFor="sources" style={{ color: 'red' }}>
        - {sources?.length} {sources.length === 1 ? 'Source' : 'Sources'}
      </label>
      <ul id="sources">
        {sources?.map((source) => (
          <>
            {source?.publication && (
              <li>
                {source?.publication}. ({source?.publicationYear})<br />
                {source?.link && (
                  <a
                    href={source?.link}
                    style={{
                      wordBreak: 'break-word',
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {source?.link}
                  </a>
                )}
                <br />
                {source?.author && <i>By {source?.author}</i>}
              </li>
            )}
          </>
        ))}
      </ul>
    </>
  )
}

export default ArticleSource
