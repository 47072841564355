import React from 'react'
import PropTypes from 'prop-types'
import Explanation from '../Explanation'
import Answers from '../Answers'
import classes from './Test.module.scss'

const Test = ({
  selectedAnswer,
  info,
  question,
  answers,
  answersJson,
  explanation,
  handleAnswer,
}) => {
  const isAnswered = selectedAnswer
  return (
    <div className={classes.questionText}>
      <div
        className={classes.info}
        dangerouslySetInnerHTML={{ __html: info }}
      />
      <div
        className={classes.question}
        dangerouslySetInnerHTML={{ __html: question }}
      />
      <hr />
      <Answers
        selectedAnswer={selectedAnswer}
        answers={answers}
        answersJson={answersJson}
        handleAnswer={handleAnswer}
      />
      {isAnswered && <Explanation value={explanation} />}
    </div>
  )
}

Test.propTypes = {
  info: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  explanation: PropTypes.string.isRequired,
  // answers: PropTypes.shape([]).isRequired,
  selectedAnswer: PropTypes.bool,
  handleAnswer: PropTypes.func.isRequired,
}

Test.defaultProps = {
  selectedAnswer: false,
}
export default Test
