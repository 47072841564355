import React from 'react'
import { Script } from 'gatsby'
import HandleRenderContent from './HandleRenderContent'
import replaceText from '../../utils/replaceText'

const ArticleContent = React.memo(
  ({ content, pathname, count, article,tokens, location }) => {
    const contentWithSteps = content.filter((it) => {
      return it?.internal?.type == 'DatoCmsHowtostep'
    })

    contentWithSteps.forEach((element, i) => {
      element.stepNumber = i + 1
    })

    // For HowTo Section
    const contentHowto = content.filter((it) => {
      return it?.internal?.type == 'DatoCmsHowto'
    })

    const getURL = (element) => {
      return `${process.env.GATSBY_SITE_BASE_URL}/${article?.category?.category?.slug
        }/${article?.category?.slug}/${article?.slug}#${element?.title
          .toLowerCase()
          .replaceAll(',', '')
          .replace(/\s/g, '-')}`
    }

    let contentWithStepsNew = []

    contentHowto.forEach((item) => {
      contentWithStepsNew = item.howtosteps
      contentWithStepsNew?.forEach((element, i) => {
        element.stepNumber = i + 1
      })
    })

    let howToSchema = []
    const data = contentHowto.map((element) => {
      const stepsSchema = element.howtosteps.map((e) => {
        return {
          '@type': 'HowToStep',
          name: e?.title,
          text: replaceText(tokens,
            e.body?.[0]?.markdownNode?.childMarkdownRemark?.rawMarkdownBody,
          ),
          url: getURL(e),
        }
      })
      howToSchema = {
        '@context': 'https://schema.org',
        '@type': 'HowTo',
        name: replaceText(tokens, element.title),
        step: [...stepsSchema],
      }
      return howToSchema
    })
    return (
      <>
        {data[0] && (
          <Script
            defer
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `${JSON.stringify(data[0])}`,
            }}
          />
        )}
        {content.map((element, index) => {
          return (
            <HandleRenderContent
              key={index}
              element={element}
              count={count}
              pathname={pathname}
              location={location}
              article={article}
              tokens={tokens}
            />
          )
        })}
      </>
    )
  },
)

export default ArticleContent
