import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Results.module.scss'

const Results = ({ questions, answers, passPercentage, handleReset }) => {
  const countCorrect = (quesArg, ansArg) => {
    return quesArg.reduce((output, question, index) => {
      return JSON.parse(question.answersJson)[ansArg[index]?.answerKey]
        ? output + 1
        : output
    }, 0)
  }

  const total = questions.length
  const correct = countCorrect(questions, answers)
  const percentage = (correct / total) * 100

  return (
    <div className={`${styles.result} result`}>
      {percentage >= passPercentage ? <h2>Pass</h2> : <h2>Fail</h2>}
      <h4>
        <b>{`Score: ${correct}/${total} - ${Math.round(percentage)}%`}</b>
      </h4>
      <h4>
        <b>{`Pass: ${passPercentage}%`}</b>
      </h4>
      <p>
        The test is complete. You can go back to the questions again and find
        explanations for the questions there.
      </p>
      {percentage >= passPercentage ? null : (
        <button
          type="button"
          className={classNames('btn', 'btn-orange', styles.tryAgain)}
          onClick={handleReset}
        >
          Try again
        </button>
      )}
    </div>
  )
}

Results.propTypes = {
  questions: PropTypes.array.isRequired,
  answers: PropTypes.object.isRequired,
  passPercentage: PropTypes.number.isRequired,
  handleReset: PropTypes.func.isRequired,
}

export default Results
