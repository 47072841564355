import React from 'react'

const LearningIcon = (props) => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="24.0156"
        cy="24.491"
        r="24"
        fill="#FFEAB4"
        fillOpacity="0.6"
      />
      <path
        d="M30.8906 34.125H29.5156V32.0625C29.5145 31.1512 29.152 30.2774 28.5076 29.633C27.8632 28.9886 26.9895 28.6261 26.0781 28.625H21.9531C21.0418 28.6261 20.1681 28.9886 19.5237 29.633C18.8792 30.2774 18.5167 31.1512 18.5156 32.0625V34.125H17.1406V32.0625C17.1421 30.7866 17.6496 29.5634 18.5518 28.6612C19.454 27.759 20.6772 27.2515 21.9531 27.25H26.0781C27.354 27.2515 28.5773 27.759 29.4795 28.6612C30.3817 29.5634 30.8892 30.7866 30.8906 32.0625V34.125Z"
        fill="#E3650C"
      />
      <path
        d="M16.4531 17.625C16.2708 17.625 16.0959 17.6974 15.967 17.8264C15.8381 17.9553 15.7656 18.1302 15.7656 18.3125V24.5H17.1406V18.3125C17.1406 18.1302 17.0682 17.9553 16.9393 17.8264C16.8103 17.6974 16.6355 17.625 16.4531 17.625Z"
        fill="#E3650C"
      />
      <path
        d="M15.7656 14.875V16.25H19.2031V21.0625C19.2031 22.3389 19.7102 23.5629 20.6127 24.4655C21.5152 25.368 22.7393 25.875 24.0156 25.875C25.292 25.875 26.5161 25.368 27.4186 24.4655C28.3211 23.5629 28.8281 22.3389 28.8281 21.0625V16.25H32.2656V14.875H15.7656ZM20.5781 16.25H27.4531V18.3125H20.5781V16.25ZM24.0156 24.5C23.1039 24.5 22.2296 24.1378 21.5849 23.4932C20.9403 22.8485 20.5781 21.9742 20.5781 21.0625V19.6875H27.4531V21.0625C27.4531 21.9742 27.091 22.8485 26.4463 23.4932C25.8016 24.1378 24.9273 24.5 24.0156 24.5Z"
        fill="#E3650C"
      />
    </svg>
  )
}

export default LearningIcon
