exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aptitude-tests-home-js": () => import("./../../../src/pages/aptitude-tests-home.js" /* webpackChunkName: "component---src-pages-aptitude-tests-home-js" */),
  "component---src-pages-cv-resume-review-js": () => import("./../../../src/pages/cv-resume-review.js" /* webpackChunkName: "component---src-pages-cv-resume-review-js" */),
  "component---src-pages-cv-review-js": () => import("./../../../src/pages/cv-review.js" /* webpackChunkName: "component---src-pages-cv-review-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-js": () => import("./../../../src/pages/latest.js" /* webpackChunkName: "component---src-pages-latest-js" */),
  "component---src-pages-privacy-and-terms-js": () => import("./../../../src/pages/privacy-and-terms.js" /* webpackChunkName: "component---src-pages-privacy-and-terms-js" */),
  "component---src-pages-resume-review-js": () => import("./../../../src/pages/resume-review.js" /* webpackChunkName: "component---src-pages-resume-review-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-templates-aptitude-test-js": () => import("./../../../src/templates/aptitude-test.js" /* webpackChunkName: "component---src-templates-aptitude-test-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-subcategory-js": () => import("./../../../src/templates/subcategory.js" /* webpackChunkName: "component---src-templates-subcategory-js" */)
}

