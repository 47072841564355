import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styles from '../../scss/content.module.scss'
import replaceText from '../../utils/replaceText'

const CoverImage = (({ coverImage, title,tokens }) => {
  if (coverImage) {
    return (
      <>
        <GatsbyImage
          loading='eager'
          image={coverImage?.gatsbyImageData}
          alt={replaceText(tokens, title)}
          imgClassName={styles.coverImage}
          className={'pb-5 mb-5'}
          as={'div'}
          id={'cover-image'}
        />
      </>
    )
  }
  return <></>
})

export default CoverImage
