import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Row, Col } from '../../lib'
import styles from './subcategory.module.scss'
import SubCategoryArticles from '../SubCategoryArticles/SubCategoryArticles'

const SubCategories = ({ categories, urlPrefix, site, articles, tokens }) => (
  <Row className={styles.subcategoriesRow}>
    {categories
      ? categories
        .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
        .map(({ node: { id, slug, title, coverImage } }) => {
          const showArticles = articles.edges.filter(
            ({ node }) => node.category.slug === slug,
          )
          return showArticles.length ? (
            <>
              <Col
                xs={12}
                md={12}
                lg={12}
                key={id}
                className={styles.subcategoryColumn}
              >
                <Link
                  className={styles.articleLink}
                  to={`${urlPrefix}/${slug}`}
                >
                  <h2 className={styles.articleTitle}>{title}</h2>
                </Link>
              </Col>
              <SubCategoryArticles
                site={site}
                articles={articles.edges.filter(
                  ({ node }) => node.category.slug === slug,
                )}
                limit={8}
                tokens={tokens}
              />
            </>
          ) : (
            <></>
          )
        })
      : null}
  </Row>
)

SubCategories.propTypes = {
  categories: PropTypes.array.isRequired,
  urlPrefix: PropTypes.string.isRequired,
}

export default SubCategories
