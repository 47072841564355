import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import replaceText from '../../utils/replaceText'

const fallbackImage =
  'https://www.datocms-assets.com/7756/1600624248-wikijob-large-2.png?w=220,auto=format,compress'

const ItemImage = ({ image, title = '', loading = 'lazy', fadeIn = true,tokens }) => {
  return image ? (
    <GatsbyImage
      image={image.gatsbyImageData}
      alt={replaceText(tokens,title)}
      className="article-cover-image"
      loading={loading}
      fadeIn={fadeIn}
    />
  ) : (
    <div
      className="article-cover-image"
      style={{ backgroundImage: `url(${fallbackImage})` }}
    />
  )
}
export default ItemImage
