const addTidLinks = (string, locationPathname) => {
  let tempString = string // a temp copy of string to manipulate
  const regex = /tests.wikijob.co.uk.*?"/gm // string should contain tests.wikijob.co.uk followed by multiple characters until " is encountered

  if (tempString?.match(regex) === null) return tempString // if no match is found

  const pathList = locationPathname.split('/').filter((item) => item)
  const resultPath = []
  resultPath.unshift(pathList.pop())

  // eslint-disable-next-line no-nested-ternary
  const tid3suffix = locationPathname.includes('/content/')
    ? '&tid3=articles'
    : locationPathname.includes('/aptitude-tests/')
    ? '&tid3=aptitude-tests'
    : ''

  tempString?.match(regex).forEach((element) => {
    const prevUrl = element.substring(0, element.length - 1)
    tempString = tempString.replace(
      element,
      `${prevUrl}?tid1=${resultPath
        .join('-')
        .substring(0, 25)}&tid2=body${tid3suffix}${process.env.TID4}"`,
    )
  }) // string.match(regex) will return array of all the occurrences

  return tempString
}

module.exports = (string, locationPathname) => {
  return addTidLinks(string, locationPathname)
}
