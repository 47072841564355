import React from 'react'

const BlockQuote = ({ html, style }) => {
  if (style === 'panel') {
    return (
      <blockquote className="mv-skip cheerioBlockquoteBox">
        <div
          className="mv-skip "
          {...{
            dangerouslySetInnerHTML: { __html: html },
          }}
        />
      </blockquote>
    )
  }
  return (
    <blockquote className="cheerioBlockquote">
      <div
        {...{
          dangerouslySetInnerHTML: { __html: html },
        }}
      />
    </blockquote>
  )
}

export default BlockQuote
