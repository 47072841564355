import React from 'react'
import classNames from 'classnames'
import styles from './BlockInterviewPerformance.module.scss'

const SidebarLinks = ({ sidebarLinks }) => {
  return (
    <div className="w-100">
      <div className={classNames(styles.sidebar, 'shadow-sm')}>
        <div className={styles.sidebarHeader}>
          Improve Aptitude Test Performance
        </div>
        <div className={styles.sidebarListGroup}>
          {sidebarLinks?.edges?.map(({ node: { url, id, text } }) => (
            <a
              data-name="blockInterviewPerformance"
              {...{
                className: classNames(styles.sidebarListGroupItem),
                target: '_blank',
                rel: 'nofollow noopener noreferrer sponsored',
                href: url,
                key: id,
              }}
            >
              {text}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SidebarLinks
