import React, { useState, useMemo, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { useLocation } from '@reach/router'
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from '../../../../common/lib'
import HeaderSearch from '../../../../common/components/Search'
import styles from './FirstMenu.module.scss'
import MainSitePopup from '../Popup/MainSitePopup'
import { MegaMenu } from '../../../../common/components'
import ThemeToggleComponent from '../ThemeToggler/ThemeToggler'

const FirstMenu = ({ location, scrollableLayout }) => {
  const path = location.pathname
  const [open, updateOpen] = useState(false)
  const [showpopup, setShowpopup] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(true)
  const locations = useLocation()
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  const updateWindowWidth = () => {
    setWindowWidth(typeof window !== 'undefined' ? window.innerWidth : 0);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowWidth);

      return () => {
        window.removeEventListener('resize', updateWindowWidth);
      };
    }
  }, []);

  useEffect(() => {
    setIsMenuOpen(true)
  }, [locations])

  const handleMenuCollapse = () => {
    setIsMenuOpen(false)
    updateOpen(false)
  }

  const data = useStaticQuery(graphql`
    query allFirstMenus {
      megamenu: allDatoCmsMegaMenu(filter: { title: { eq: "WikiJob.co.uk" } }) {
        nodes {
          title
          treeChildren {
            title
            link
            position
            treeChildren {
              title
              link
              position
              treeChildren {
                title
                link
                position
              }
            }
          }
        }
      }
      popup: allDatoCmsPopup {
        nodes {
          mailerliteGroupId
          heading
          regexPathMatch
          subheading
          image {
            gatsbyImageData(
              width: 400
              height: 600
              imgixParams: { fit: "crop" }
            )
          }
        }
      }
    }
  `)

  const handlePopup = (value) => {
    setShowpopup(value)
  }

  const content = useMemo(() => {
    const popupNodes = data.popup.nodes
    const index = popupNodes.findIndex((item) =>
      location.pathname.match(item.regexPathMatch),
    )
    if (index === -1) {
      return null
    }
    return popupNodes[index]
  }, [data, location.pathname])

  return (
    <>
      
      <Navbar
        id="header"
        color="light"
        light
        expand="md"
        container="xxl"
        // Conditional styling below - for the articles pages only
        className={`${scrollableLayout ? 'position-relative' : 'position-sticky'
          } ${styles.responsiveNavbar}`}
      >
        <NavbarBrand
          tag={Link}
          to={'/'}
          {...{
            className: styles.navbarBrand,
          }}
        >
          WikiJob
        </NavbarBrand>
        {
          windowWidth <= 769 ? (
            <div className={`${styles.navButton}`}>
              {/* <ThemeToggleComponent /> */}
              <button
                onClick={() => updateOpen(!open)}
                aria-label="Toggle navigation"
                type="button"
                className={`FirstMenu-module--toggleNav--2Tcqh navbar-toggler ${styles.hamburger}`}
                
              >
                <span
                  className={`navbar-toggler-icon ${styles.navbarTogglerIconSvg}`}
                />
              </button>
            </div>
          ) : ""
        }
        <Collapse isOpen={open} navbar className={styles.navCollapse}>
          <Nav navbar className={styles.nestedNacCollapse}>
            {data?.megamenu?.nodes?.map((menuItem, i) => (
              <MegaMenu
                menuData={menuItem}
                isMenuOpen={isMenuOpen}
                handleMenuCollapse={handleMenuCollapse}
                key={i}
              />
            ))}

            {content === null ? null : (
              <NavItem>
                <NavLink
                  className="btn btn-orange"
                  onClick={() => setShowpopup(true)}
                >
                  <span style={{ fontWeight: 'bold', color: 'white' }}>
                    Subscribe
                  </span>
                </NavLink>
              </NavItem>
            )}
            {/* {
              windowWidth > 770 ? (
                <ThemeToggleComponent />
              ) : ""
            } */}
            <li>
              <HeaderSearch location={location} />
            </li>
          </Nav>
        </Collapse>
      </Navbar>
      {showpopup && <MainSitePopup ispopupopen={handlePopup} showPopup />}
    </>
  )
}

export default FirstMenu
