import React from 'react'
import style from './style.module.scss'

const QuestionAnswer = ({ questionHtml, answerHtml }) => {
  const [showAnswer, setShowAnswer] = React.useState(false)
  const handleShowAnswer = () => {
    setShowAnswer(!showAnswer)
  }
  return (
    <div className={`card mt-4 mb-4 mv-skip ${style.question}`}>
      <div className={`ps-2 rounded text-center ${style.titleLabel}`}>
        Example Question
      </div>
      <div className="card-body">
        <div
          {...{
            dangerouslySetInnerHTML: {
              __html: questionHtml.replace(/null/g, ''),
            },
          }}
        />
        {showAnswer && (
          <div
            {...{
              dangerouslySetInnerHTML: { __html: answerHtml },
            }}
          />
        )}
        <button
          type="button"
          className="btn btn-orange"
          onClick={handleShowAnswer}
        >
          {!showAnswer ? 'Show Answer' : 'Hide Answer'}
        </button>
      </div>
    </div>
  )
}

export default QuestionAnswer
