import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import decorateURLWithTID from '../../utils/decorateUrlTid'
import replaceYear from '../../utils/replaceYear'
import ProgressBar from './ProgressBar'
import { Disclaimer } from '..'
import replaceText from '../../utils/replaceText'

const StickyArticleHeader = ({ article, location, scrollIndicator, tokens }) => {
  return (
    <>
      <div className={styles.stickyArticleHeader}>
        <div
          className={`${styles.stickyArticleHeaderContainer} `}
        >
          <span className={styles.text_left}>
            {replaceText(tokens, article.heroText ? article.heroText : article.title)}
          </span>

          {article.heroButtonText && article.heroButtonText && (
            <a
              data-name="stickyHeaderButton"
              href={decorateURLWithTID(
                article.heroButtonUrl,
                location.pathname,
                'stickyheader',
              )}
              className={`btn text-white ${styles.stickyArticleHeaderButton}`}
              role="button"
              aria-pressed="true"
              rel="sponsored nofollow noreferrer"
              target="_blank"
            >
              {article.heroButtonText}
            </a>
          )}
        </div>
        {article.heroDisclaimer && (
          <div className="">
            <Disclaimer disclaimer={article.heroDisclaimer} tokens={tokens} />
          </div>
        )}
      </div>
      {/* show ProgressBar on scroll if scrollIndicator */}
      {scrollIndicator && <ProgressBar />}
    </>
  )
}

// added prop types for StickyArticleHeader component
StickyArticleHeader.propTypes = {
  article: PropTypes.shape({
    heroText: PropTypes.string.isRequired,
    heroButtonText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  scrollIndicator: PropTypes.bool,
}
// added default prop for scrollIndicator as false
StickyArticleHeader.defaultProps = {
  scrollIndicator: false,
}

export default StickyArticleHeader
