import React from 'react'
import isEmpty from 'lodash-es/isEmpty'
import { Row, Col } from '../../lib'
import style from './style.module.scss'

const DoDont = ({ html }) => {
  const renderHtml = (htmlContent, title) => {
    return htmlContent.replace(/<[^>]+>/g, '') !== 'null' ? (
      <>
        <h4 className="mt-0">{title}</h4>
        <div
          {...{
            dangerouslySetInnerHTML: {
              __html: htmlContent,
            },
          }}
        />
      </>
    ) : (
      <></>
    )
  }
  return !isEmpty(html) ? (
    <Row className={style.prosCons}>
      <Col sm={12} md={6} className={`${style.pros} col-12`}>
        {html?.doNode?.childMarkdownRemark &&
          renderHtml(html?.doNode?.childMarkdownRemark?.html, 'Do\'s')}
      </Col>
      <Col sm={12} md={6} className={`${style.cons} col-12`}>
        {html?.dontNode?.childMarkdownRemark &&
          renderHtml(html?.dontNode?.childMarkdownRemark?.html, 'Don\'ts')}
      </Col>
    </Row>
  ) : (
    <></>
  )
}

export default DoDont
