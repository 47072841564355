import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { window } from 'browser-monads'
import {
  Edit,
  Volume2,
  Layers,
  Settings,
  PieChart,
  Command,
} from 'react-feather'
import decorateURLWithTID from '../../../../common/utils/decorateUrlTid'
import './style.css'
import styles from './SecondMenu.module.scss'

const iconColor = '#777'

const SecondMenu = ({ location }) => {
  const [state, setState] = useState({
    iconSize: '40',
    minWidth: 'inherit',
  })

  const data = useStaticQuery(graphql`
    query iconMenu {
      menuLinks: allDatoCmsWikicoukMenu {
        edges {
          node {
            id
            text
            url
            icon
          }
        }
      }
    }
  `)

  // eslint-disable-next-line consistent-return
  const iconFeather = (icon) => {
    switch (icon) {
      case 'fa-edit':
        return (
          <Edit
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'fa-calculator':
        return (
          <PieChart
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'fa-bullhorn':
        return (
          <Volume2
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'fa-gavel':
        return (
          <Command
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'fa-chart-bar':
        return (
          <Layers
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      case 'fa-cogs':
        return (
          <Settings
            {...{
              size: state.iconSize,
              color: iconColor,
            }}
          />
        )
      default:
        break
    }
  }

  const resize = () => {
    if (window.innerWidth <= 991) {
      setState({
        iconSize: '20',
        minWidth: '190px',
      })
    } else {
      setState({
        iconSize: '40',
        minWidth: 'inherit',
      })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
    // eslint-disable-next-line
  }, [window])

  return (
    <div className={`d-none d-sm-none d-md-block ${styles.wrap}`}>
      <nav className="container-xxl nav nav-pills nav-fill second-menu">
        {data.menuLinks.edges
          .slice(0, 6)
          .map(({ node: { url, id, icon, text } }) => (
            <a
              data-name="icon-link"
              {...{
                style: { minWidth: state.minWidth },
                className: classNames(
                  'nav-item nav-link icon-link track',
                  styles.navItem,
                ),
                rel: 'nofollow noopener noreferrer',
                target: '_blank',
                href: decorateURLWithTID(
                  url,
                  location.pathname,
                  'iconmenu',
                  'wiki',
                ),
                key: id,
              }}
            >
              {iconFeather(icon)}
              &nbsp;
              {text}
            </a>
          ))}
      </nav>
    </div>
  )
}

export default SecondMenu
