import React from 'react'
import { ArrowUpCircle } from 'react-feather'
import style from './ScrollToTop.module.scss'

const ScrollToTopButton = ({ isScrollToTopShown }) => {
  return (
    <div className={`${style.scrollToTopContainer} d-none d-md-block`}>
      <a
        className={`${style.scrollToTop} ${
          isScrollToTopShown ? `${style.show}` : ''
        }`}
        href="#top"
      >
        <ArrowUpCircle />
      </a>
    </div>
  )
}

export default ScrollToTopButton
