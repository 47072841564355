import { window } from 'browser-monads'

export const scrollToNextQuestion = () => {
  const card =
    document.getElementsByClassName(`questionContent`)[
    0
    ]
  if (card !== undefined) {
    const cardOffsetTop = card.offsetTop
    if (window.innerWidth <= 767) {
      window.scrollTo({ top: cardOffsetTop - 70, behavior: 'smooth' })
    } else if (window.innerWidth >= 768 && window.innerWidth <= 991) {
      window.scrollTo({ top: cardOffsetTop - 150, behavior: 'smooth' })
    } else if (window.innerWidth >= 992) {
      window.scrollTo({ top: cardOffsetTop - 130, behavior: 'smooth' })
    }
  }
  return null
}

export const handleAnswer = (
  questionIndex,
  answerIndex,
  setIndex,
  setanswers,
  setQuestionIndex,
  answersx,
  name,
) => {
  const indexs = questionIndex
  const answerss = {
    ...answersx,
    [questionIndex]: { index: answerIndex, answerKey: name },
  }
  setIndex(indexs)
  setanswers(answerss)
  setQuestionIndex(questionIndex)

  // TODO: Indicator for answered questions
  const countOfLabels = document
    .getElementsByClassName('questionContent')
  [0].getElementsByTagName('label')
  for (let indx = 0; indx < countOfLabels?.length; indx += 1) {
    const hasAnswer = countOfLabels
      ?.item(indx)
      ?.classList?.contains('answeredPoint')
    if (hasAnswer) {
      countOfLabels?.item(indx).classList.remove('answeredPoint')
    }
  }
  const answeredPoint = countOfLabels?.[answerIndex]
  answeredPoint.classList.add('answeredPoint')

  scrollToNextQuestion()
}

export const handleNavigate = (setShowResults) => {
  setShowResults(true)
  setTimeout(() => {
    const practiceMore = document.getElementsByClassName(`result`)[0]
    practiceMore?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }, 300)
}

export const handleScroll = (key) => {
  // TODO: прокрутка, вынести в отдельную функцию

  const card = document.getElementsByClassName(`questionContent`)[0]
  const cardOffsetTop = card.offsetTop
  if (window.innerWidth <= 767) {
    window.scrollTo({ top: cardOffsetTop - 70, behavior: 'smooth' })
  } else if (window.innerWidth >= 768 && window.innerWidth <= 991) {
    window.scrollTo({ top: cardOffsetTop - 150, behavior: 'smooth' })
  } else if (window.innerWidth >= 992) {
    window.scrollTo({ top: cardOffsetTop - 130, behavior: 'smooth' })
  }
}

export const isCorrect = (key, props) => {
  const { questions, answers } = props
  const question = questions[key]

  const answersJson = JSON.parse(question.answersJson)

  if (answers[key]) {
    if (answersJson[answers[key].answerKey]) return true
  }
  return null
}
