import React, { useState, useEffect } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classes from './styles.module.scss'

const ProgressBar = () => {
  // create a state for scroll to calculate scroll
  const [scrolled, setScrolled] = useState(0)

  const scrollProgress = () => {
    // get scroll
  }
  useScrollPosition(() => {
    scrollProgress()
  })

  useEffect(() => {
    function updateNavigation() {
      const windowHeight = window.innerHeight
      const viewportTop = document.documentElement.scrollTop
      const viewportMiddle = viewportTop + windowHeight / 2

      const contentSections = document.getElementsByClassName(`articleScroll`)
      if (contentSections) {
        Array.from(contentSections).forEach(function (item) {
          const itemHeight = item.getBoundingClientRect().height
          const { offsetTop } = item
          const offsetBottom = offsetTop + itemHeight
          if (offsetTop < viewportMiddle && offsetBottom > viewportMiddle) {
            const scrollPercent =
              ((viewportMiddle - offsetTop) / itemHeight) * 100
            const progressheight = scrollPercent
            setScrolled(`${progressheight}%`)
          }
        })
      }
    }
    function eventlist() {
      updateNavigation()
    }

    window.onscroll = eventlist

    updateNavigation()
  }, [])

  return (
    <div className={classes.progressContainer}>
      <div className={classes.progressBar} style={{ width: scrolled }} />
    </div>
  )
}

export default ProgressBar
