import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Row, Col } from '../../../../common/lib'
import SubscribeToNewsletter from '../SubscribeToNewsletter'
import SocialMediaLinks from './SocialMediaLinks'
import styles from './Footer.module.scss'

const Footer = ({ location }) => {
  const currentYear = new Date().getFullYear()

  const data = useStaticQuery(graphql`
    query footerQuery {
      footerLinks: allDatoCmsFooterGrid {
        edges {
          node {
            id
            title
            column {
              id
              label
              url
            }
          }
        }
      }
    }
  `)

  const interviewSectionMailerLiteGroupId = 109118684
  const tradingMailerLiteGroupId = 107242519
  const mailerliteGroupId = location?.pathname?.includes('trading')
    ? tradingMailerLiteGroupId
    : interviewSectionMailerLiteGroupId

  return (
    <footer className={styles.footer}>
      <Row className="container">
        {data.footerLinks.edges.map(renderColumn)}
      </Row>
      {/* Should be shown after implementation of functionality of external form */}
      {/* <SubscribeToNewsletter mailerliteGroupId={mailerliteGroupId} /> */}
      <Row className="container">
        <Col md={2} lg={1} className={`${styles.footer_logo} pe-3`}>
          WikiJob
        </Col>
        <Col md={3} className={styles.socialMediaLinks}>
          <SocialMediaLinks />
        </Col>
        <Col md={7} lg={8} className={styles.copyright}>
          © WikiJob 2007-{currentYear}. All rights reserved.
        </Col>
      </Row>
    </footer>
  )
}

const renderColumn = ({ node: { id, title, column } }) => (
  <Col
    lg={2}
    md={2}
    key={id}
    className={`col ${styles.column} ${styles.navLinks}`}
  >
    <div className={`pr-4 ${styles.columnHeading}`}>{title}</div>
    {column.map(renderLink)}
  </Col>
)

const renderLink = ({ id, label, url }) => (
  <div key={id}>
    <a href={url} className="footerLink text-decoration-none">
      {label}
    </a>
  </div>
)

export default Footer
