import React from 'react'
import Button from '../../Button'
import { useWizard } from 'react-use-wizard'
import { handleScroll, isCorrect } from '../../../utils/aptitudeHelpers'

function PaginatioComp(props) {
  const {
    questions,
    lastQuestion,
    fromAptitudePage = undefined,
    goToStep,
    currentQuestion,
  } = props
  const { activeStep } = useWizard()
  return (
    <>
      <div>
        <nav>
          <ul
            className="pagination flex-wrap"
            style={{ justifyContent: 'center' }}
          >
            {questions.map((question, key) => (
              <li
                {...{
                  key,
                  className: 'page-item',
                }}
              >
                <Button
                  {...{
                    className: 'page-link',
                    value: String(key + 1),
                    handleClick: () => {
                      if (goToStep) goToStep(key)
                      return handleScroll(key)
                    },

                    isCurrentQuestion: key === activeStep,
                    // isCorrect: isCorrect(key, props),  If question is correct highlights that number
                  }}
                />
              </li>
            ))}
          </ul>
        </nav>
        <div className="text-center pt-2">
          {!fromAptitudePage && lastQuestion && (
            <button
              type="button"
              className="btn btn-success text-white btn-lg"
              style={{ zoom: 1.2 }}
              onClick={() => props.handleNavigate(true)}
            >
              Finish test & show result
            </button>
          )}
          {fromAptitudePage && (
            <button
              type="button"
              className="btn btn-success text-white btn-lg"
              style={{ zoom: 1.2 }}
              onClick={() => props.handleNavigate(true)}
            >
              Finish test & show result
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default PaginatioComp
