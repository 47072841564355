function articleUrl(article, site) {
  if (article?.shortUrl) {
    return `/${article?.slug}`
  }
  return `/${article?.category?.category?.slug}/${article?.category?.slug}/${article?.slug}`
}

module.exports = (article, site) => {
  return articleUrl(article, site)
}
