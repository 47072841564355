import React from 'react'
import isEmpty from 'lodash-es/isEmpty'
import { Row, Col } from '../../lib'
import style from './style.module.scss'
import replaceText from '../../utils/replaceText'

const ProsCons = ({ html, tokens }) => {
  const renderHtml = (htmlContent, title) => {
    return htmlContent.replace(/<[^>]+>/g, '') !== 'null' ? (
      <>
        <h4 className="mt-0">{replaceText(tokens, title)}</h4>
        <div
          {...{
            dangerouslySetInnerHTML: {
              __html: replaceText(tokens, htmlContent),
            },
          }}
        />
      </>
    ) : (
      <></>
    )
  }
  return !isEmpty(html) ? (
    <Row className={style.prosCons}>
      <Col sm={12} md={6} className={`${style.pros} col-12`}>
        {html?.proNode?.childMarkdownRemark &&
          renderHtml(html?.proNode?.childMarkdownRemark?.html, 'Pros')}
      </Col>
      <Col sm={12} md={6} className={`${style.cons} col-12`}>
        {html?.conNode?.childMarkdownRemark &&
          renderHtml(html?.conNode?.childMarkdownRemark?.html, 'Cons')}
      </Col>
    </Row>
  ) : (
    <></>
  )
}

export default ProsCons
