import React from 'react'

const TestIcon = (props) => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="24.0156"
        cy="24.491"
        r="24"
        fill="#FFEAB4"
        fillOpacity="0.6"
      />
      <path
        d="M20.8164 24.1C20.8164 23.3574 21.1114 22.6453 21.6365 22.1201C22.1616 21.595 22.8738 21.3 23.6164 21.3C24.359 21.3 25.0712 21.595 25.5963 22.1201C26.1214 22.6453 26.4164 23.3574 26.4164 24.1C26.4164 24.8427 26.1214 25.5548 25.5963 26.0799C25.0712 26.6051 24.359 26.9001 23.6164 26.9001C22.8738 26.9001 22.1616 26.6051 21.6365 26.0799C21.1114 25.5548 20.8164 24.8427 20.8164 24.1ZM23.6164 22.7C23.2451 22.7 22.889 22.8475 22.6265 23.1101C22.3639 23.3727 22.2164 23.7287 22.2164 24.1C22.2164 24.4714 22.3639 24.8274 22.6265 25.09C22.889 25.3526 23.2451 25.5 23.6164 25.5C23.9877 25.5 24.3438 25.3526 24.6064 25.09C24.8689 24.8274 25.0164 24.4714 25.0164 24.1C25.0164 23.7287 24.8689 23.3727 24.6064 23.1101C24.3438 22.8475 23.9877 22.7 23.6164 22.7Z"
        fill="#E3650C"
      />
      <path
        d="M15.9164 17.1001C15.3595 17.1001 14.8253 17.3213 14.4315 17.7152C14.0377 18.109 13.8164 18.6431 13.8164 19.2001V29.0001C13.8164 29.5571 14.0377 30.0912 14.4315 30.485C14.8253 30.8788 15.3595 31.1001 15.9164 31.1001H31.3164C31.8734 31.1001 32.4075 30.8788 32.8013 30.485C33.1952 30.0912 33.4164 29.5571 33.4164 29.0001V19.2001C33.4164 18.6431 33.1952 18.109 32.8013 17.7152C32.4075 17.3213 31.8734 17.1001 31.3164 17.1001H15.9164ZM15.2164 19.2001C15.2164 19.0144 15.2902 18.8364 15.4214 18.7051C15.5527 18.5738 15.7308 18.5001 15.9164 18.5001H18.0164V19.9001C18.0164 20.2714 17.8689 20.6275 17.6064 20.89C17.3438 21.1526 16.9877 21.3001 16.6164 21.3001H15.2164V19.2001ZM15.2164 22.7001H16.6164C17.359 22.7001 18.0712 22.4051 18.5963 21.88C19.1214 21.3549 19.4164 20.6427 19.4164 19.9001V18.5001H27.8164V19.9001C27.8164 20.6427 28.1114 21.3549 28.6365 21.88C29.1616 22.4051 29.8738 22.7001 30.6164 22.7001H32.0164V25.5001H30.6164C29.8738 25.5001 29.1616 25.7951 28.6365 26.3202C28.1114 26.8453 27.8164 27.5575 27.8164 28.3001V29.7001H19.4164V28.3001C19.4164 27.5575 19.1214 26.8453 18.5963 26.3202C18.0712 25.7951 17.359 25.5001 16.6164 25.5001H15.2164V22.7001ZM29.2164 18.5001H31.3164C31.5021 18.5001 31.6801 18.5738 31.8114 18.7051C31.9427 18.8364 32.0164 19.0144 32.0164 19.2001V21.3001H30.6164C30.2451 21.3001 29.889 21.1526 29.6265 20.89C29.3639 20.6275 29.2164 20.2714 29.2164 19.9001V18.5001ZM32.0164 26.9001V29.0001C32.0164 29.1857 31.9427 29.3638 31.8114 29.4951C31.6801 29.6263 31.5021 29.7001 31.3164 29.7001H29.2164V28.3001C29.2164 27.9288 29.3639 27.5727 29.6265 27.3101C29.889 27.0476 30.2451 26.9001 30.6164 26.9001H32.0164ZM18.0164 29.7001H15.9164C15.7308 29.7001 15.5527 29.6263 15.4214 29.4951C15.2902 29.3638 15.2164 29.1857 15.2164 29.0001V26.9001H16.6164C16.9877 26.9001 17.3438 27.0476 17.6064 27.3101C17.8689 27.5727 18.0164 27.9288 18.0164 28.3001V29.7001Z"
        fill="#E3650C"
      />
      <path
        d="M34.8173 29C34.8173 29.9283 34.4486 30.8185 33.7922 31.4749C33.1358 32.1313 32.2456 32.5 31.3173 32.5H16.7363C16.8811 32.9097 17.1494 33.2643 17.5042 33.515C17.859 33.7657 18.2829 33.9003 18.7173 33.9H31.3173C32.6169 33.9 33.8632 33.3838 34.7822 32.4649C35.7011 31.5459 36.2173 30.2996 36.2173 29V22C36.2176 21.5656 36.083 21.1418 35.8323 20.787C35.5815 20.4322 35.2269 20.1639 34.8173 20.019V29Z"
        fill="#E3650C"
      />
    </svg>
  )
}

export default TestIcon
