/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Search } from 'react-feather'
import classNames from 'classnames'
import { NavItem } from '../../lib'

import styles from './HeaderSearch.module.scss'

const HeaderSearch = ({
  location,
  open = false,
  setOpen,
  hideSearchIcon = false,
}) => {
  const [search, updateSearch] = useState('')
  const [modal, setModal] = useState()
  const path = location?.pathname || ''

  const onPressEnterSearch = (event) => {
    const e = event.nativeEvent
    if (e.code === 'Enter' && typeof window !== 'undefined') {
      setModal(false)
      navigate(`/search?q=${search}`)
      updateSearch('')
    }
  }

  function openModal() {
    setModal(false)
    setOpen && setOpen(false)
  }

  useEffect(() => {
    const input = document.querySelector(`.${styles.searchFormControl}`)
    if (modal) {
      input.value = ''
      const inputMain = document.querySelector(`.${styles.modalSearch}`)
      inputMain.addEventListener('click', openModal)
      input.focus()
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  }, [modal])
  return (
    <>
      {
        // if it isn't the 'job searching' page show the search
        !path?.match('\\/uk\\/jobs') && (
          <>
            {!hideSearchIcon && (
              <NavItem
                className={classNames(
                  'input-group',
                  'justify-content-center',
                  styles.searchField,
                  'search-icon',
                )}
              >
                <p
                  {...{
                    disabled: search.length === 0,
                    className: classNames(
                      'search-button bg-transparent ps-2 pe-2 d-inline-block d-md-table-cell m-0',
                      styles.inputGroupAddon,
                    ),
                  }}
                  onClick={() => setModal(true)}
                >
                  <Search size="20" color="var(--search-icon-color)" />
                </p>
              </NavItem>
            )}
          </>
        )
      }
      <div
        className={classNames('modal', styles.modalSearch)}
        style={{ display: `${modal || open ? 'block' : ''}` }}
        id="myModal"
        role="dialog"
      >
        <div className="mw-100 mt-5 w-75 mx-auto">
          <div className="d-inline">
            <button
              onClick={() => {
                setModal(false)
                setOpen && setOpen(false)
              }}
              type="button"
              className={`btn btn-default ps-0 ps-md-4 ${styles.button}`}
              data-dismiss="modal"
            >
              &times; <span className="position-relative">Close</span>
            </button>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div className={`modal-content shadow ${styles.modelSearchBox}`}>
              <div className="py-4">
                {
                  // if it isn't the 'job searching' page show the search
                  !path.match('\\/uk\\/jobs') && (
                    <NavItem
                      className={classNames(
                        'input-group px-4 w-100 mx-auto m-0',
                        styles.searchField,
                      )}
                    >
                      <input
                        {...{
                          type: 'text',
                          className: classNames(
                            'w-100 ',
                            styles.searchFormControl,
                          ),
                          placeholder: 'Search',
                          value: search,
                          onChange: (e) => updateSearch(e.target.value),
                          onKeyPress: onPressEnterSearch,
                        }}
                      />
                    </NavItem>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeaderSearch
