import React from 'react'

const SocialMediaLinks = () => {
  return (
    <>
      <a href="https://twitter.com/wikijob" aria-label="Twitter-Address">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.633 7.99698C19.646 8.17198 19.646 8.34598 19.646 8.51998C19.646 13.845 15.593 19.981 8.186 19.981C5.904 19.981 3.784 19.32 2 18.172C2.324 18.209 2.636 18.222 2.973 18.222C4.78599 18.2264 6.54765 17.6201 7.974 16.501C7.13342 16.4858 6.31858 16.2084 5.64324 15.7077C4.9679 15.207 4.46578 14.5079 4.207 13.708C4.456 13.745 4.706 13.77 4.968 13.77C5.329 13.77 5.692 13.72 6.029 13.633C5.11676 13.4488 4.29647 12.9543 3.70762 12.2337C3.11876 11.513 2.79769 10.6106 2.799 9.67998V9.62998C3.336 9.92898 3.959 10.116 4.619 10.141C4.06609 9.77357 3.61272 9.27501 3.29934 8.68977C2.98596 8.10454 2.82231 7.45084 2.823 6.78698C2.823 6.03898 3.022 5.35298 3.371 4.75498C4.38314 6 5.6455 7.01854 7.07634 7.74464C8.50717 8.47074 10.0746 8.8882 11.677 8.96998C11.615 8.66998 11.577 8.35898 11.577 8.04698C11.5767 7.51794 11.6807 6.99404 11.8831 6.50522C12.0854 6.0164 12.3821 5.57226 12.7562 5.19817C13.1303 4.82408 13.5744 4.52739 14.0632 4.32506C14.5521 4.12273 15.076 4.01872 15.605 4.01898C16.765 4.01898 17.812 4.50498 18.548 5.29098C19.4498 5.1166 20.3145 4.7874 21.104 4.31798C20.8034 5.2488 20.1738 6.03809 19.333 6.53798C20.1328 6.44676 20.9144 6.23643 21.652 5.91398C21.1011 6.71708 20.4185 7.42133 19.633 7.99698Z"
            fill="white"
          />
        </svg>
      </a>
      <a href="https://www.facebook.com/WikiJob" aria-label="Facebook-Address">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.19776 21.5H13.1978V13.49H16.8018L17.1978 9.51H13.1978V7.5C13.1978 7.23478 13.3031 6.98043 13.4907 6.79289C13.6782 6.60536 13.9325 6.5 14.1978 6.5H17.1978V2.5H14.1978C12.8717 2.5 11.5999 3.02678 10.6622 3.96447C9.72454 4.90215 9.19776 6.17392 9.19776 7.5V9.51H7.19776L6.80176 13.49H9.19776V21.5Z"
            fill="white"
          />
        </svg>
      </a>
      <a
        href="https://www.linkedin.com/company/wikijob/"
        aria-label="LinkedIn-Address"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.98292 7.19698C6.19132 7.19698 7.17092 6.21738 7.17092 5.00898C7.17092 3.80058 6.19132 2.82098 4.98292 2.82098C3.77452 2.82098 2.79492 3.80058 2.79492 5.00898C2.79492 6.21738 3.77452 7.19698 4.98292 7.19698Z"
            fill="white"
          />
          <path
            d="M9.23722 8.85499V20.994H13.0062V14.991C13.0062 13.407 13.3042 11.873 15.2682 11.873C17.2052 11.873 17.2292 13.684 17.2292 15.091V20.995H21.0002V14.338C21.0002 11.068 20.2962 8.55499 16.4742 8.55499C14.6392 8.55499 13.4092 9.56199 12.9062 10.515H12.8552V8.85499H9.23722ZM3.09521 8.85499H6.87021V20.994H3.09521V8.85499Z"
            fill="white"
          />
        </svg>
      </a>
      <a
        href="https://www.instagram.com/wikijob.co.uk"
        aria-label="Instagram-Address"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.9471 8.30502C20.937 7.54764 20.7952 6.79779 20.5281 6.08902C20.2964 5.49117 19.9426 4.94822 19.4892 4.49485C19.0359 4.04148 18.4929 3.68767 17.8951 3.45602C17.1954 3.19338 16.4562 3.05136 15.7091 3.03602C14.7471 2.99302 14.4421 2.98102 12.0001 2.98102C9.55807 2.98102 9.24507 2.98102 8.29007 3.03602C7.54323 3.05147 6.80442 3.19349 6.10507 3.45602C5.50713 3.68751 4.96409 4.04126 4.5107 4.49465C4.05732 4.94804 3.70356 5.49108 3.47207 6.08902C3.2089 6.78815 3.06719 7.52713 3.05307 8.27402C3.01007 9.23702 2.99707 9.54202 2.99707 11.984C2.99707 14.426 2.99707 14.738 3.05307 15.694C3.06807 16.442 3.20907 17.18 3.47207 17.881C3.70395 18.4788 4.05797 19.0216 4.51151 19.4748C4.96505 19.928 5.50813 20.2816 6.10607 20.513C6.8035 20.7862 7.54244 20.9384 8.29107 20.963C9.25407 21.006 9.55907 21.019 12.0011 21.019C14.4431 21.019 14.7561 21.019 15.7111 20.963C16.4582 20.9483 17.1974 20.8066 17.8971 20.544C18.4948 20.3121 19.0376 19.9582 19.4909 19.5049C19.9442 19.0515 20.2982 18.5087 20.5301 17.911C20.7931 17.211 20.9341 16.473 20.9491 15.724C20.9921 14.762 21.0051 14.457 21.0051 12.014C21.0031 9.57202 21.0031 9.26202 20.9471 8.30502ZM11.9941 16.602C9.44007 16.602 7.37107 14.533 7.37107 11.979C7.37107 9.42502 9.44007 7.35602 11.9941 7.35602C13.2202 7.35602 14.396 7.84308 15.263 8.71006C16.13 9.57704 16.6171 10.7529 16.6171 11.979C16.6171 13.2051 16.13 14.381 15.263 15.248C14.396 16.115 13.2202 16.602 11.9941 16.602ZM16.8011 8.26302C16.2041 8.26302 15.7231 7.78102 15.7231 7.18502C15.7231 7.04352 15.7509 6.90341 15.8051 6.77268C15.8592 6.64195 15.9386 6.52317 16.0387 6.42311C16.1387 6.32306 16.2575 6.24369 16.3882 6.18954C16.519 6.13539 16.6591 6.10752 16.8006 6.10752C16.9421 6.10752 17.0822 6.13539 17.2129 6.18954C17.3436 6.24369 17.4624 6.32306 17.5625 6.42311C17.6625 6.52317 17.7419 6.64195 17.7961 6.77268C17.8502 6.90341 17.8781 7.04352 17.8781 7.18502C17.8781 7.78102 17.3961 8.26302 16.8011 8.26302Z"
            fill="white"
          />
          <path
            d="M11.9942 14.982C13.6527 14.982 14.9972 13.6375 14.9972 11.979C14.9972 10.3205 13.6527 8.97601 11.9942 8.97601C10.3357 8.97601 8.99121 10.3205 8.99121 11.979C8.99121 13.6375 10.3357 14.982 11.9942 14.982Z"
            fill="white"
          />
        </svg>
      </a>
      <a
        href="https://www.pinterest.co.uk/WikiJob/"
        aria-label="Pinterest-Address"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.99 2C6.472 2 2 6.473 2 11.99C2 16.222 4.633 19.84 8.35 21.296C8.262 20.506 8.184 19.29 8.384 18.428C8.566 17.648 9.556 13.462 9.556 13.462C9.556 13.462 9.257 12.863 9.257 11.978C9.257 10.59 10.062 9.553 11.065 9.553C11.918 9.553 12.329 10.193 12.329 10.96C12.329 11.818 11.783 13.099 11.502 14.287C11.267 15.281 12.001 16.092 12.981 16.092C14.756 16.092 16.122 14.22 16.122 11.517C16.122 9.125 14.403 7.453 11.949 7.453C9.106 7.453 7.437 9.585 7.437 11.788C7.437 12.646 7.768 13.567 8.181 14.068C8.2161 14.1057 8.24088 14.1519 8.25297 14.202C8.26505 14.2521 8.26403 14.3044 8.25 14.354C8.174 14.669 8.005 15.348 7.973 15.487C7.929 15.67 7.828 15.709 7.638 15.621C6.391 15.04 5.611 13.216 5.611 11.75C5.611 8.599 7.9 5.705 12.212 5.705C15.678 5.705 18.371 8.174 18.371 11.475C18.371 14.919 16.2 17.688 13.187 17.688C12.174 17.688 11.223 17.163 10.897 16.542L10.274 18.916C10.049 19.784 9.44 20.872 9.033 21.536C9.99111 21.8318 10.9883 21.9818 11.991 21.981C17.508 21.981 21.981 17.508 21.981 11.991C21.981 6.474 17.507 2 11.99 2Z"
            fill="white"
          />
        </svg>
      </a>
    </>
  )
}

export default SocialMediaLinks
