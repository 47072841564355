import React from 'react'
import style from './Disclaimer.module.scss'
import replaceText from '../../utils/replaceText'
import RawHtmlScript from '../ArticleContent/RawHtmlScript'

const Disclaimer = ({ disclaimer, tokens }) => {
  return (
    <p className={`text-muted p-0 m-0 px-3 ${style.disclaimer}`}>
      <RawHtmlScript html={replaceText(tokens, disclaimer)} />
    </p>
  )
}

export default Disclaimer
