import * as React from 'react'

export function useHeadings() {
  const [headings, setHeadings] = React.useState([])
  React.useEffect(() => {
    const elements = Array.from(document.querySelectorAll('h2'))
      .filter((element) => element.id)
      .map((element) => ({
        id: element.id,
        text: element.textContent ?? '',
        level: Number(element.tagName.substring(1)),
      }))
    setHeadings(elements)
  }, [])
  return headings
}
