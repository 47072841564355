const replaceText = (wordsToReplace, string) => {
  var str = string
  wordsToReplace &&
    wordsToReplace.map((word) => {
      var markdown =
        word?.node?.valueMarkdown[0]?.markdownNode?.childMarkdownRemark?.html
      var reg = new RegExp(word?.node?.key, 'ig')
      str = str?.replace(reg, markdown ? markdown : word?.node?.value)
      return str
    })
  return str
}

module.exports = (wordsToReplace, string) => {
  return replaceText(wordsToReplace, string)
}
