export const features = [
  'Taking Realistic Tests',
  'Using Accurate Practice',
  'Get Explanations of Tutorials',
  'Have Full Online Access',
  '24/7 Customer Support',
]

export const mainFeatures = [
  'Aptitude Tests Platform',
  'Fast Learning Results',
  'Get the Job You Want',
]
