import React from 'react'

import decorateUrlTid from '../../utils/decorateUrlTid'
import styles from "../../scss/content.module.scss"

const CallToActionButton = ({ item, pathname,cta=true }) => {
  const { link, text } = item
  const startsWith = () => {
    if (
      link.startsWith('/') ||
      link.toLowerCase().startsWith('https://www.wikijob.co.uk') ||
      link.toLowerCase().startsWith('https://psychometric-success.com') ||
      link.toLowerCase().startsWith('https://wikijob.com')
    )
      return true
    return false
  }
  return (
    <p>
      <a data-name='articleButtons'
        {...{
          className:
            `${cta? `text-white btn btn-orange btn-lg ${styles.fullButton}`:''}`,
          ...(!startsWith() && { rel: 'nofollow noopener' }),
          href: pathname ? decorateUrlTid(link, pathname, 'cta') : link,
          target: '_blank',
        }}
      >
        {text}
      </a>
    </p>
  )
}

export default CallToActionButton
