import React, { useState, useContext, useEffect } from 'react'
import { useLocation } from '@reach/router'
import '../../../../common/css/subscribeToNewsletterStyles.css'
import GeoContext from '../../../utils/geoContext'

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}
const SubscribeToNewsletter = ({
  popup,
  mailerliteGroupId = '',
  setHasSubcribed,
}) => {
  const { pathname } = useLocation()
  const placement = popup ? 'popup' : 'footer'
  const formName = 'subscribe'
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [geo, setGeo] = useState('')

  const countryData = useContext(GeoContext)
  useEffect(() => {
    if (countryData) {
      setGeo(countryData.geo)
    }
  }, [])

  // const {geo}=countryData

  const handleChange = (e) => {
    setError('')
    setEmail(e.target.value)
  }
  const [isLoading, setIsLoading] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    const emailRegex =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    const isValidEmail = emailRegex.test(email)
    if (!isValidEmail) {
      setError('Enter a Valid Email')
      e.preventDefault()
      return
    }

    const reqData = {
      'form-name': formName,
      path: pathname,
      placement,
      city: countryData?.geo?.city,
      country: countryData?.geo?.country_code,
      mailerliteGroupId,
    }

    setIsLoading(true)
    e.preventDefault()
    try {
      await Promise.all([
        fetch('/', {
          method: 'POST',
          body: encode({
            email,
            ...reqData,
          }),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }),
        fetch('/.netlify/functions/mailerlite', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            fields: reqData,
          }),
        }),
      ])
      setFormSubmitted(true)
      setEmail('')
      setIsLoading(false)
      if (setHasSubcribed) {
        setHasSubcribed(true)
      }
    } catch (reqError) {
      setError('Try again later')
      setIsLoading(false)
      setEmail('')
    }
  }
  return (
    <div className="subscribe-to-newsletter container">
      {!popup && <p>Sign Up for Our Newsletter</p>}

      {/* This could be replaced with needed external form (from iframe) */}
      {formSubmitted && popup ? null : (
        <form
          name={formName}
          data-netlify="true"
          netlify-honeypot="bot-field"
          className="subscribe-form"
        >
          <label htmlFor="bot-field" className="d-none">
            Don’t fill this out if you’re human:{' '}
            <input id="bot-field" name="bot-field" />
          </label>

          <input type="hidden" name="placement" value={placement} />
          <input
            type="hidden"
            name="mailerliteGroupId"
            value={mailerliteGroupId}
          />
          <input type="hidden" name="city" value={countryData?.geo?.city} />
          <input type="hidden" name="country" value={geo?.country_code} />
          <input type="hidden" name="path" value={pathname} />
          <input type="hidden" name="form-name" value={formName} />
          <div className="position-relative">
            <input
              required
              disabled={isLoading}
              type="email"
              value={email}
              onChange={handleChange}
              name="email"
              placeholder="Your Email"
              style={{
                boxShadow: `${popup ? '1px 1px 3px lightsteelblue' : 'none'}`,
              }}
            />
            <button disabled={isLoading} onClick={handleSubmit} type="submit">
              {isLoading ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                'Subscribe'
              )}
            </button>
          </div>
          {error && <div className="d-block invalid-feedback">{error}</div>}
        </form>
      )}
    </div>
  )
}

export default SubscribeToNewsletter
