import React from 'react'
import sortMenu from '../../utils/sortMenu'
import DropdownIcon from '../Icons/DropdownIcon'
import styles from './MegaMenu.module.scss'
import { noFollowUrls } from '../../utils/noFollowLinks'

const MegaMenu = ({
  menuData,
  isMenuOpen,
  handleMenuCollapse,
  setIsMenuOpen,
}) => {
  if (menuData.title === 'Psychometric-Success.com') {
    menuData.treeChildren.filter((item) => item.title !== 'Practice Tests')
  }

  return (
    <li className={styles.navbarContainer}>
      {sortMenu(menuData?.treeChildren)?.map(
        ({ title: menuTitle, treeChildren, link }, i) => {
          let isAvailable = true
          //  This condition can remove Practice Test of sub-links from wj-uk
          if (
            menuData.title === 'WikiJob.co.uk' &&
            menuTitle === 'Practice Tests'
          ) {
            isAvailable = false
          }
          //  This condition can remove nav item (Practice Test) from Psychometric
          if (
            menuData.title === 'Psychometric-Success.com' &&
            menuTitle === 'Practice Tests'
          ) {
            return
          }

          return (
            <div
              onMouseEnter={() => {
                if (setIsMenuOpen) {
                  setIsMenuOpen(true)
                }
              }}
              onMouseLeave={() => {
                if (setIsMenuOpen) {
                  setIsMenuOpen(false)
                }
              }}
              className={styles.navbarLink}
              key={i}
            >
              <div className={styles.navbarLinkContainer}>
                {/* <span className={styles.title}> */}
                {link !== '' ? (
                  noFollowUrls.includes(link) ? (
                    <a href={link} rel="nofollow" className={styles.title}>
                      {menuTitle}
                    </a>
                  ) : (
                    <a href={link} className={styles.title}>
                      {menuTitle}
                    </a>
                  )
                ) : (
                  <span className={styles.title}>{menuTitle}</span>
                )}
                {/* </span> */}
                {isAvailable && <DropdownIcon />}
              </div>
              {isAvailable && isMenuOpen && (
                <div className={styles.outerSheet}>
                  <div className={styles.sheet}>
                    <div className={styles.inner}>
                      {sortMenu(treeChildren)?.map(
                        (
                          {
                            title: subMenuTitle,
                            treeChildren: nestedChild,
                            link: subMenuLink,
                          },
                          i,
                        ) => {
                          return (
                            <div className={styles.col} key={i}>
                              <div>
                                {/* <div className="megahead"> */}
                                {subMenuLink?.length > 0 ? (
                                  noFollowUrls.includes(link) ? (
                                    <a
                                      href={subMenuLink}
                                      onClick={handleMenuCollapse}
                                      rel="nofollow"
                                    >
                                      {subMenuTitle}
                                    </a>
                                  ) : (
                                    <a
                                      href={subMenuLink}
                                      onClick={handleMenuCollapse}
                                    >
                                      {subMenuTitle}
                                    </a>
                                  )
                                ) : (
                                  subMenuTitle
                                )}
                                {/* </div> */}
                              </div>
                              {nestedChild.length !== 0 ? (
                                <ul>
                                  {sortMenu(nestedChild)?.map(
                                    (
                                      { title: childTitle, link: childLink },
                                      i,
                                    ) =>
                                      childLink ? (
                                        <li key={i}>
                                          {noFollowUrls.includes(link) ? (
                                            <a
                                              href={childLink}
                                              onClick={handleMenuCollapse}
                                              rel="nofollow"
                                            >
                                              {childTitle}
                                            </a>
                                          ) : (
                                            <a
                                              href={childLink}
                                              onClick={handleMenuCollapse}
                                            >
                                              {childTitle}
                                            </a>
                                          )}
                                        </li>
                                      ) : null,
                                  )}
                                </ul>
                              ) : null}
                            </div>
                          )
                        },
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        },
      )}
    </li>
  )
}

export default MegaMenu
