import React, { useEffect } from 'react'
import { useScrollSpy } from '../Hooks/use-active-hash'
import { useHeadings } from '../Hooks/useHeadings'
import style from './tableOfContent.module.scss'
import replaceText from '../../utils/replaceText'

const cleanText = (text, counter) => {
  if (!text) return ''
  const formatedText = text
    .replace(/ /g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
    .toLowerCase()

  if (!counter) return formatedText
  return `${counter}-${formatedText}`
}

export default function TableOfContent({
  data,
  faqs = [],
  location,
  maxHeight,
  tokens
}) {

  const headings = useHeadings()
  const activeId = useScrollSpy(
    headings.map(({ id }) => id),
    { rootMargin: '0% 0% -55% 0%' },
  )


  useEffect(() => {
    var allElements = document.querySelectorAll('.activeLink')
    for (let i = 0; i < allElements.length; i++) {
      allElements[i].classList.remove('activeLink')
    }
    activeId &&
      document.getElementById(`#${activeId}`)?.classList?.add('activeLink')
  }, [activeId])

  const headingMatches = []
  const tocArray = data
    .map((e) => {
      if (e?.internal?.type === 'DatoCmsMarkdown') {
        const headingRe = /<h3[^>]*>(.*?)<\/h3>/gm
        let match
        while (
          (match = headingRe.exec(
            e?.markdownNode?.childMarkdownRemark?.html,
          )) !== null
        ) {
          headingMatches.push(match[1].replace(/<[^>]+>/g, ''))
        }
        return replaceText(tokens,
          e?.markdownNode?.childMarkdownRemark?.tableOfContents,
        )
      } else if (e?.internal?.type === 'DatoCmsHowto') {
        return `<li>
        <a href=#${cleanText(replaceText(tokens, e?.title))}  >
          ${replaceText(tokens, e?.title)}
        </a></li>`
      }
      else {
        return replaceText(tokens, e?.markdownNode?.childMarkdownRemark?.html)
      }
    })
    .filter((item) => item)

  const newHtml = tocArray
    .join('')
    .replace(/(\r\n|\n|\r)/gm, '')
    .replace(/<\/?p[^>]*>/gm, '')
    .replace(/<\/?ul[^>]*>/gm, '')
    .replaceAll('</li></li>', '</li>')

  const reg = /<a [^>]*\bhref\s*=\s*"([^"]*)">(.*?)<\/a>/gm

  const addClass = (newHtml) => {
    const html = newHtml.replace(reg, (match, id, text) => {
      const isExist = headingMatches.indexOf(text) === -1
      return !isExist
        ? `<p>empty</p>`
        : `<a href=${id}>
         ${text?.replace(/&amp;/g, '&')}
          </a>`
    })
    return html
  }

  return (
    <>
      {newHtml === '' ? null : (
        <ul
          aria-label="Table of Contents"
          className={`tableOfContent ${style.toc}`}
          // style={{ maxHeight: maxHeight ? maxHeight : 'auto' }}
          dangerouslySetInnerHTML={{
            __html: addClass(newHtml),
          }}
        />
      )}
    </>
  )
}
