module.exports = (url, locationPathname, tid) => {
  // if (tid === 'linkly' || tid === 'hero' || tid === 'stickyheader') {

  // }

  if (
    !url.trim().startsWith('https://tests.wikijob.co.uk/') &&
    !url.trim().startsWith('https://link.psychometric-success.com') &&
    !url.trim().startsWith('https://tests.wikijob.com/')
  ) {
    return url
  }

  const tid3suffix = locationPathname.includes('/content/')
    ? '&tid3=articles'
    : locationPathname.includes('/aptitude-tests/')
      ? '&tid3=aptitude-tests'
      : locationPathname.includes('/course/')
        ? '&tid3=aptitude-tests'
        : ''

  const pathList = locationPathname.split('/').filter((item) => item)
  const resultPath = []
  resultPath.unshift(pathList.pop())

  var result = locationPathname.includes('/course/') ? resultPath : resultPath.join('-').substring(0, 25)

  return `${url}?tid1=${result}&tid2=${tid}${tid3suffix}${process.env.TID4}`
}