import React, { useContext } from 'react'
import { Link } from 'gatsby'
import articleUrl from '../../utils/articleUrl'
import replaceYear from '../../utils/replaceYear'
import ItemImage from './ItemImage'
import replaceText from '../../utils/replaceText'

const SeeAlsoItem = ({ item, tokens }) => {
  return (
    <Link className="article-link" to={articleUrl(item)}>
      <ItemImage tokens={tokens} image={item.coverImage == null ? item.insetImage : item.coverImage} title={item.title} eager="eager" />
      <span className="article-title">{replaceText(tokens, item.title)}</span>
    </Link>
  )
}

export default SeeAlsoItem
