import React from 'react'
import { Disclaimer } from '..'
import ProsCons from './ProsCons'
import RawHtmlScript from './RawHtmlScript'
import replaceText from '../../utils/replaceText'
import styles from "../../scss/content.module.scss"

const cleanText = (text, counter) => {
  if (!text) return ''
  const formatedText = text
    .replace(/ /g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
    .toLowerCase()

  return `${counter}-${formatedText}`
}

const Top10Items = ({ item, counter, tokens }) => {
  const { ctaUrl, title, bodyNode, modularContent = [] } = item
  return (
    <>
      <h3 id={cleanText(title, counter)}>
        {`${counter}. `}
        <a href={ctaUrl} rel="sponsored nofollow " target="_blank">
          {replaceText(tokens, title)}
        </a>
      </h3>
      <div
        {...{
          dangerouslySetInnerHTML: {
            __html: replaceText(tokens, bodyNode.childMarkdownRemark.html),
          },
        }}
      />
      {modularContent?.map((content, i) => {
        if (content?.internal?.type === 'DatoCmsProCon')
          return <ProsCons key={i} html={content} tokens={tokens}  />
        else if (content?.internal?.type === 'DatoCmsMarkdown') {
          return (
            <RawHtmlScript
              key={i}
              html={replaceText(tokens, content?.markdownNode?.childMarkdownRemark.html)}
            />
          )
        }
      })}
      <p>
        <a
          data-name="articleButtons"
          className={`btn btn-orange btn-lg ${styles.fullButton}`}
          href={ctaUrl}
          rel="sponsored nofollow"
          target="_blank"
        >
          {`Visit ${replaceText(tokens, title)}`}
        </a>
      </p>
      <Disclaimer disclaimer={item.disclaimer} tokens={tokens} />
    </>
  )
}

export default Top10Items
