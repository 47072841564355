import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import replaceText from '../../utils/replaceText'

const AuthorImage = (({ article,tokens }) => {
  if (article.author && article.author.picture.gatsbyImageData) {
    return (
      <Link
        to={`/team/${article.author.name.toLowerCase().split(' ').join('-')}`}
      >
        <GatsbyImage
          className="rounded-circle"
          image={article.author.picture.gatsbyImageData}
          alt={replaceText(tokens,article.author.name)}
          loading="eager"
          fadeIn={false}
        />
      </Link>
    )
  }

  return <></>
})

export default AuthorImage
