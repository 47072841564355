const formatDate = (date, type) => {
  switch (type) {
    case 'title':
      return new Date(date).toLocaleString('en-us', {
        month: 'long',
        year: 'numeric',
      })

    default:
      return new Date(date).toLocaleString('en-us', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
  }
}

module.exports = (date, type) => {
  return formatDate(date, type)
}
