import React, { useState, useEffect, useContext } from 'react'
import { window } from 'browser-monads'
import Test from '../../../wikijob/src/components/Test'
import Results from '../../../wikijob/src/components/Results'
import PaginationComp from './PaginationComp'
import './styles.scss'
import {
  handleAnswer,
  handleNavigate,
  scrollToNextQuestion,
} from '../../utils/aptitudeHelpers'
import jobTestPrepImage from '../../images/job-test-prep.png'
import testHqImage from '../../images/testhq-logo.png'
import decorateUrlTid from '../../utils/decorateUrlTid'
import { Wizard, useWizard } from 'react-use-wizard'
import PracticeProblemSchema from '../PracticeProblemSchema'
import replaceText from '../../utils/replaceText'

function AptitudetestCompo(props) {
  const [, setIndex] = useState(0)
  const [answersx, setanswers] = useState({})
  const [, setQuestionIndex] = useState(0)
  const [showResults, setShowResults] = useState(false)
  const [resetTest, setResetTest] = useState(false)
  const [, setInitialOffset] = useState(0)
  const {
    data: test,
    location,
    fromAptitudePage = undefined,
    article = undefined,
    tokens
  } = props

  const handleReset = () => {
    setResetTest(true)
    setTimeout(() => {
      const allSelectedAnswers = document.querySelectorAll('.answeredPoint')

      allSelectedAnswers.forEach((element) => {
        element.classList.remove('answeredPoint')
      })
      setShowResults(false)
      setIndex(0)
      setQuestionIndex(0)
      setanswers({})
    }, 100)

    scrollToNextQuestion(-1)
  }

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'componentDidMount' })
    }
    setTimeout(setInitialOffset(window.pageYOffset), 300)
  }, [props, test.name, test.url])

  const getImageData=(url)=>{
    if(url.includes('l.wikijob.co.uk')){
      return {
        src:testHqImage,
        alt:'Testhq',
        className: 'jobTestPrepImage',
      }
    }else{
      return {
        src: jobTestPrepImage,
        alt: 'JobTestPrep',
        className: 'jobTestPrepImage',
      }
    }
  }
  

  return (
    <>
      <PracticeProblemSchema
        questions={test && test?.questions}
        title={replaceText(tokens,
          article?.metaTitle || article?.title || test.metaTitle,
        )}
        description={
          (article?.metaDescription && replaceText(tokens, article?.metaDescription)) ||
          test.metaDescription
        }
      />
      {test.introText && (
        <div className="card" style={{ position: 'static' }}>
          <div className="card-body text-center">
            <div
              className={fromAptitudePage ? '' : 'heading_banner'}
              dangerouslySetInnerHTML={{ __html: test.introText }}
            />
            {fromAptitudePage && (
              <a
                {...{
                  href: decorateUrlTid(
                    test.url,
                    location.pathname,
                    'jtplogo',
                    'wiki',
                  ),
                  rel: 'sponsored',
                  className: 'jobTestPrepLink',
                  target:'_blank'
                }}
              >
                <span>Powered by</span>
                
                <img
                  {...getImageData(test.url)}
                />
              </a>
            )}
          </div>
        </div>
      )}
      <div className="allQuestion">
        <div className="allQuestionList">
          <Wizard>
            {test.questions.map(
              (
                { infoNode, questionNode, explanationNode, answersJson, id },
                questionIndex,
              ) => {
                const totalQuestions = test.questions.length
                return (
                  <WizardChild
                    key={id}
                    url={test.url}
                    handleNavigate={handleNavigate}
                    questions={test.questions}
                    handleAnswer={handleAnswer}
                    name={test.name}
                    questionIndex={questionIndex}
                    id={id}
                    infoNode={infoNode}
                    questionNode={questionNode}
                    explanationNode={explanationNode}
                    totalQuestions={totalQuestions}
                    answersJson={answersJson}
                    showResults={showResults}
                    resetTest={resetTest}
                    answersx={answersx}
                    setShowResults={setShowResults}
                    setResetTest={setResetTest}
                    location={location}
                    setIndex={setIndex}
                    setanswers={setanswers}
                    setQuestionIndex={setQuestionIndex}
                    fromAptitudePage={fromAptitudePage}
                  />
                )
              },
            )}
          </Wizard>
          {showResults && (
            <>
              <Results
                {...{
                  questions: test.questions,
                  answers: answersx,
                  passPercentage: test.passPercentage,
                  handleReset,
                }}
              />
              {fromAptitudePage && (
                <div className="practiceMore">
                  <a
                    data-name="practiceMoreBtn"
                    {...{
                      className: 'btn btn-orange btn-lg',
                      rel: 'sponsored',
                      href: decorateUrlTid(
                        test.url,
                        location.pathname,
                        'practicemore',
                        'wiki',
                      ),
                      target: '_blank',
                    }}
                  >
                    CLICK HERE TO PRACTICE MORE TESTS
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AptitudetestCompo

function WizardChild({
  questionIndex,
  id,
  infoNode,
  questionNode,
  explanationNode,
  totalQuestions,
  answersJson,
  name,
  questions,
  handleNavigate,
  handleAnswer,
  url,
  showResults,
  resetTest,
  answersx,
  location,
  fromAptitudePage,
  setIndex,
  setanswers,
  setQuestionIndex,
  setShowResults,
  setResetTest,
}) {
  const { nextStep, goToStep } = useWizard()

  useEffect(() => {
    const countOfLabels = document
      .getElementsByClassName('questionContent')[0]
      .getElementsByTagName('label')
    for (let indx = 0; indx < countOfLabels?.length; indx += 1) {
      const hasAnswer = countOfLabels
        ?.item(indx)
        ?.classList?.contains('answeredPoint')
      if (hasAnswer) {
        countOfLabels?.item(indx).classList.remove('answeredPoint')
      }
    }
    const answeredPoint = countOfLabels?.[answersx?.[questionIndex]?.index]
    answeredPoint?.classList?.add('answeredPoint')
  }, [questionIndex, showResults])

  useEffect(() => {
    if (resetTest === true) {
      goToStep(0)
      setResetTest(false)
    }
  }, [resetTest])

  return (
    <div key={id}>
      <div
        {...{
          className: 'questionContent',
          key: id,
        }}
      >
        <div className="questionCount">
          {name}: question {questionIndex + 1} of {totalQuestions}
        </div>
        <Test
          {...{
            info: infoNode.childMarkdownRemark.html,
            question: questionNode.childMarkdownRemark.html,
            explanation: explanationNode.childMarkdownRemark.html,
            answersJson: JSON.parse(answersJson),
            selectedAnswer: showResults,
            handleAnswer: (answerIndex, name) => {
              nextStep()

              return handleAnswer(
                questionIndex,
                answerIndex,
                setIndex,
                setanswers,
                setQuestionIndex,
                answersx,
                name,
              )
            },
          }}
        />
        <div className="questionPagination">
          <PaginationComp
            {...{
              fromAptitudePage,
              answers: answersx,
              questions: questions,
              handleNavigate: () => handleNavigate(setShowResults),
              lastQuestion: questionIndex === questions.length - 1,
              goToStep: goToStep,
            }}
          />
        </div>
      </div>
      {fromAptitudePage && (
        <div className="practiceMore">
          <a
            data-name="practiceMoreBtn"
            {...{
              className: 'btn btn-orange btn-lg',
              rel: 'sponsored',
              href: decorateUrlTid(
                url,
                location.pathname,
                'practicemore',
                'wiki',
              ),
              target: '_blank',
            }}
          >
            CLICK HERE TO PRACTICE MORE TESTS
          </a>
        </div>
      )}
      <div className="row">
        <div className="content_hint" />
      </div>
    </div>
  )
}
