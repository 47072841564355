import React from 'react'
import { decorateURLWithTID } from '../../utils'
import { Disclaimer } from '..'
import classes from './HeroBox.module.scss'

const button = (text, url, color, block, id) => (
  <div className="col-md pt-3">
    <a
      // id={id}
      data-name="Hero Button 1"
      className={`btn btn-primary btn-lg ${classes.heroButton} ${
        block ? 'd-block' : null
      }`}
      role="button"
      href={url}
      rel="nofollow sponsored noreferrer"
      target="_blank"
      style={{
        background: color,
      }}
    >
      {text}
    </a>
  </div>
)

const HeroBox = ({
  location,
  article,
  id = 'heroButton1',
  site,
  tokens,
  tid2 = 'hero',
}) => (
  <>
    <nav className={classes.jumbotron}>
      <div style={{ verticalAlign: 'middle' }}>
        <div className="d-flex text-center justify-content-center">
          <span className={classes.heroText}>{article.heroText}</span>
        </div>
        <div className="d-flex justify-content-center pt-4">
          <div className="container">
            <div className="text-center">
              {article.heroButtonText &&
                article.heroButtonUrl &&
                button(
                  article.heroButtonText,
                  decorateURLWithTID(
                    article.heroButtonUrl,
                    location.pathname,
                    tid2,
                  ),
                  '#32cd32',
                  article.heroButtonText2,
                  id,
                )}

              {article.heroButtonText2 &&
                article.heroButtonUrl2 &&
                button(
                  article.heroButtonText2,
                  decorateURLWithTID(
                    article.heroButtonUrl2,
                    location.pathname,
                    tid2,
                  ),
                  '#ff9100',
                  article.heroButtonText2,
                  'heroButton2',
                )}
            </div>
          </div>
        </div>
      </div>
    </nav>
    {site === 'wiki' && article?.heroDisclaimer && (
      <div className="pb-4 px-2 text-center">
        <Disclaimer disclaimer={article.heroDisclaimer} tokens={tokens} />
      </div>
    )}
  </>
)

export default HeroBox
