import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import "./themetoggler.css"
import { Sun, Moon } from 'react-feather'

class ThemeToggleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: 'light',
    };
  }

  componentDidMount() {
    // Check the initial theme based on the body element's class
    const bodyClassList = document.body.classList;
    if (bodyClassList.contains('dark')) {
      this.setState({ theme: 'dark' });
    } else {
      this.setState({ theme: 'light' });
    }
  }

  toggleTheme = () => {
    this.setState(prevState => ({
      theme: prevState.theme === 'light' ? 'dark' : 'light',
    }));
  }

  render() {
    const { theme } = this.state;

    return (
      <ThemeToggler>
        {({ theme: gatsbyTheme, toggleTheme }) => (
          <div className='theme-button-container'>
            {theme === 'light' && (
              <Sun
                onClick={() => {
                  toggleTheme(theme === 'light' ? 'dark' : 'light');
                  this.toggleTheme();
                }}
                className={gatsbyTheme === 'light' ? 'active' : ''}
              />
            )}
            {theme === 'dark' && (
              <Moon
                onClick={() => {
                  toggleTheme(theme === 'dark' ? 'light' : 'dark');
                  this.toggleTheme();
                }}
                className={gatsbyTheme === 'dark' ? 'active' : ''}
              />
            )}
          </div>
        )}
      </ThemeToggler>
    );
  }
}

export default ThemeToggleComponent;