import React from 'react'
import classNames from 'classnames'
import decorateURLWithTID from '../../utils/decorateUrlTid'
import TestIcon from '../Icons/TestIcon'
import LearningIcon from '../Icons/LearningIcon'
import JobIcon from '../Icons/JobIcon'
import { features, mainFeatures } from './constants'
import classes from './BuyBox.module.scss'
import { getTestUrl } from '../../utils/commonFunctions'

const BuyBox = ({
  location: { pathname = '' } = {},
  testUrl,
  positionTop,
  zIndex,
  marginTop,
}) => {
  const getUrl = () => {
    if (testUrl && pathname) {
      return decorateURLWithTID(testUrl, pathname, 'buybox')
    }
    return 'https://tests.wikijob.co.uk/hello-bar'
  }

  const getIcon = (index) => {
    if (index === 0) {
      return <TestIcon />
    }
    if (index === 1) {
      return <LearningIcon />
    }
    return <JobIcon />
  }

  return (
    <div
      id="mediavine-settings"
      data-blocklist-sidebar-btf="1"
      className={classNames('w-100', classes.buyBoxPopupContainer)}
      style={{
        position: 'sticky',
        top: positionTop || '63px',
        zIndex: zIndex || '1001',
        minHeight: '500px',
      }}
    >
      <a
        rel="sponsored nofollow noreferrer"
        target="_blank"
        data-name="allbuyBox"
        className={classNames(classes.buyBoxPopupLink)}
        style={{ marginTop: marginTop || '0' }}
        href={getUrl()}
      >
        <div className={classes.buyBoxPopupHeader}>
          <div className={classes.buyBoxPopupHeading}>
            Want to Practice More?
          </div>
          <div className={classes.buyBoxPopupMainFeatures}>
            {mainFeatures.map((feature, i) => (
              <div key={feature} className={classes.buyBoxPopupMainFeature}>
                {getIcon(i)}
                {feature}
              </div>
            ))}
          </div>
        </div>

        <div className={classes.buyBoxPopupBody}>
          <div className={classes.buyBoxPopupListGroup}>
            <div className={classes.buyBoxPopupListGroupHeading}>
              Increase Your Test Score
            </div>
            {features.map((feature) => (
              <span key={feature} className={classes.buyBoxPopupListGroupItem}>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: '5px' }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.956 7.96397C17.1659 7.75587 17.4493 7.63876 17.7449 7.63794C18.0405 7.63713 18.3245 7.75268 18.5356 7.95961C18.7466 8.16654 18.8678 8.44823 18.8728 8.74377C18.8778 9.03931 18.7664 9.32496 18.5625 9.53897L12.5745 17.024C12.4716 17.1348 12.3473 17.2238 12.2093 17.2855C12.0712 17.3473 11.9221 17.3805 11.7708 17.3833C11.6196 17.3861 11.4694 17.3584 11.3291 17.3018C11.1888 17.2452 11.0614 17.1609 10.9545 17.054L6.98697 13.085C6.87644 12.982 6.78779 12.8578 6.7263 12.7198C6.66481 12.5818 6.63175 12.4328 6.62908 12.2817C6.62642 12.1307 6.6542 11.9807 6.71078 11.8406C6.76737 11.7005 6.85158 11.5732 6.95841 11.4664C7.06524 11.3596 7.19249 11.2754 7.33257 11.2188C7.47265 11.1622 7.6227 11.1344 7.77375 11.1371C7.92481 11.1397 8.07378 11.1728 8.21178 11.2343C8.34978 11.2958 8.47398 11.3844 8.57697 11.495L11.718 14.6345L16.9275 7.99697C16.9368 7.98535 16.9468 7.97432 16.9575 7.96397H16.956Z"
                    fill="#F0873D"
                  />
                </svg>
                {feature}
              </span>
            ))}
          </div>
          <p
            data-name="buyBox"
            className="btn btn-orange btn-lg d-block"
            style={{
              borderRadius: '10px',
              padding: '0.5rem 0.8rem',
              fontSize: '18px',
              textTransform: 'uppercase',
            }}
          >
            Start Now
          </p>

          <div className={classes.buyBoxPopupLabel}>{`${`with ${getTestUrl(
            testUrl,
          )}`}`}</div>
          <div className={classes.buyBoxPopupSponsoredText}>
            <small>Sponsored</small>
          </div>
        </div>
      </a>
    </div>
  )
}

export default BuyBox
