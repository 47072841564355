import * as React from 'react'
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from '../../lib'
import cleanText from '../../utils/cleanText'

const Faqs = ({ answer, question }) => {
  return (
    <UncontrolledAccordion>
      <AccordionItem>
        <AccordionHeader id={cleanText(question)} tag="h3" targetId="1">
          {question}
        </AccordionHeader>
        <AccordionBody accordionId="1">{answer}</AccordionBody>
      </AccordionItem>
    </UncontrolledAccordion>
  )
}

export default Faqs
