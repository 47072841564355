const replaceYear = (string) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const d = new Date()
  const month = monthNames[d.getMonth()]
  const year = new Date().getFullYear()
  return string?.replace(/{YEAR}/g, year).replace(/{MONTH}/g, month)
}

module.exports = (string) => {
  return replaceYear(string)
}
