import React, { useEffect, useState } from 'react'

/**
 * @param {Element} element to be observed.
 * @return {boolean} whether the target element has transitioned into a state of intersection (true) or out of a state of intersection (false).
 */

const useIsIntersectingElement = (element) => {
  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsIntersecting(entry.isIntersecting)
      })
    })
    if (element) observer.observe(element)
  }, [element])

  return isIntersecting
}

export default useIsIntersectingElement
