import React from 'react'
import { AptitudeTestComp } from '../AptitudeTestComp'
import BlockQuote from './BlockQuote'
import CallToActionButton from './CallToActionButton'
import Image from './Image'
import Product from './Product/Product'
import ProsCons from './ProsCons'
import RawHtmlScript from './RawHtmlScript'
import Top10Items from './Top10Items'
import QuestionAnswer from './QuestionAnswer'
import Faqs from '../Faqs/Faqs'
import addTidLinks from '../../utils/addTidLinks'

const HandleHowToStep = ({ element, count, pathname,tokens }) => {
  if (!element || !element.internal) return null
  switch (element.internal.type) {
    case 'DatoCmsQuiz': {
      return <AptitudeTestComp data={element.test} />
    }
    case 'DatoCmsFaqItem': {
      return (
        <Faqs
          answer={
            <RawHtmlScript
              html={element?.answerNode?.childMarkdownRemark.html}
            />
          }
          question={element.question}
        />
      )
    }
    case 'DatoCmsExampleQuestion':
      return (
        <QuestionAnswer
          questionHtml={element.questionNode.childMarkdownRemark.html}
          answerHtml={element.answerExplanationNode.childMarkdownRemark.html}
        />
      )
    case 'DatoCmsMarkdown':
      return (
        <RawHtmlScript html={addTidLinks(element.markdownNode.childMarkdownRemark.html,pathname)} />
      )
    case 'DatoCmsProCon':
      return <ProsCons html={element} />
    case 'DatoCmsHtml':
      return <RawHtmlScript html={element.html} />
    case 'DatoCmsProduct':
      return <Product product={element} tokens={tokens} />
    case 'DatoCmsImage':
      return <Image image={element} tokens={tokens} />
    case 'DatoCmsRawHtmlScript':
      return <RawHtmlScript html={element.rawHtml} />
    case 'DatoCmsCallToActionButton':
      return <CallToActionButton item={element} pathname={pathname} />
    case 'DatoCmsBigQuote':
      return (
        <BlockQuote
          html={element.bodyNode.childMarkdownRemark.html}
          style={element.style}
        />
      )
    case 'DatoCmsTop10item':
      if (!element.published) {
        return null
      }
      count.val += 1
      return <Top10Items item={element} counter={count.val} tokens={tokens} />

    default:
      return null
  }
}

export default HandleHowToStep
