module.exports = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate]
  const result = []
  const map = new Map()

  arr.forEach((item) => {
    const key = item === null || item === undefined ? item : cb(item)

    if (!map.has(key)) {
      map.set(key, item)
      result.push(item)
    }
  })

  return result
}
