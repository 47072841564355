import React from 'react'
import { Link } from 'gatsby'
import { Row, Col } from '../../lib'
import SeeAlsoItem from './SeeAlsoItem'
import './SeeAlso.scss'
import { Helmet } from 'react-helmet'

const SeeAlso = ({ article, siteName, tokens }) => {
  if (article.seeAlso && article.seeAlso.length > 0) {
    return (
      <>
        <Helmet
          style={[
            {
              cssText: `
              .further-reading {
                position: relative;
                z-index: 3;
              }

              .further-reading .read-next-text {
                border-bottom: 2px solid #ef9969;
                border-bottom-width: 2px !important;
                color: var(--textNormal);
                display: flex;
                padding-top: 9px;
                font-family: 'Nunito Sans', sans-serif;
                margin-bottom: 15px;
                font-size: 32px;
                font-weight: 700;
                line-height: 50px;
              }
              
              .further-reading .article-link {
                display: block;
                height: 100%;
                background-color: var(--card-bg);
                overflow: hidden;
                box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
                transition: all 0.3s;
              }
              
              .further-reading .article-title {
                padding: 14px 18px 17px;
                font-size: 17px;
                line-height: 27px;
                display: inline-block;
                transition: all 0.3s;
                color: var(--seealso-card-title-color);
              }
        `,
            },
          ]}
        />
        <div className={`further-reading content px-4`}>
          <span className="read-next-text">Read This Next</span>
          <p>You might also be interested in these other {siteName} articles:</p>

          <Row>
            {article.seeAlso.map((item, i) => (
              <Col key={i} lg={3} md={4} sm={6} xs={12} className="col">
                <SeeAlsoItem item={item} tokens={tokens} />
              </Col>
            ))}
          </Row>

          <p>
            Or explore the{' '}
            <Link to={`/${article.category.category.slug}`}>
              {article.category.category.title}
            </Link>{' '}
            /{' '}
            <Link
              to={`/${article.category.category.slug}/${article.category.slug}`}
            >
              {article.category.title}
            </Link>{' '}
            sections.
          </p>
        </div>
      </>
    )
  }
  return null
}

export default SeeAlso
