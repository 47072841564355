import React from 'react'
import PropTypes from 'prop-types'
import Variant from '../Variant'
import classes from './Answers.module.scss'

// moved component from class component to functional component
const Answers = ({ selectedAnswer, answers, handleAnswer, answersJson }) => {
  const isAnswered = selectedAnswer

  return (
    <div className={classes.answersList}>
      <Variant
        {...{
          isAnswered,
          answers,
          answersJson,
          selectedAnswer,
          handleAnswer,
        }}
      />
    </div>
  )
}

Answers.propTypes = {
  isAnswered: PropTypes.bool,
  // answers: PropTypes.shape([]).isRequired,
  selectedAnswer: PropTypes.bool,
  handleAnswer: PropTypes.func.isRequired,
}
// define default props
Answers.defaultProps = {
  isAnswered: false,
  selectedAnswer: false,
}

export default Answers
