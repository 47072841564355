import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from '../utils';

const propTypes = {
    active: PropTypes.bool,
    'aria-label': PropTypes.string,
    block: PropTypes.bool,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    tag: tagPropType,
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
    onClick: PropTypes.func,
    size: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    cssModule: PropTypes.object,
    close: PropTypes.bool,
};

const defaultProps = {
    color: 'secondary',
    tag: 'button',
};

class Button extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        if (this.props.disabled) {
            e.preventDefault();
            return;
        }

        if (this.props.onClick) {
            return this.props.onClick(e);
        }
    }

    render() {
        let {
            active,
            'aria-label': ariaLabel,
            block,
            className,
            close,
            cssModule,
            color,
            outline,
            size,
            tag: Tag,
            innerRef,
            ...attributes
        } = this.props;

        const btnOutlineColor = `btn${outline ? '-outline' : ''}-${color}`;

        const classes = mapToCssModules(classNames(
            className,
            close && 'btn-close',
            close || 'btn',
            close || btnOutlineColor,
            size ? `btn-${size}` : false,
            block ? 'd-block w-100' : false,
            { active, disabled: this.props.disabled }
        ), cssModule);

        if (attributes.href && Tag === 'button') {
            Tag = 'a';
        }

        const defaultAriaLabel = close ? 'Close' : null;

        return (
            <Tag
                type={(Tag === 'button' && attributes.onClick) ? 'button' : undefined}
                {...attributes}
                className={classes}
                ref={innerRef}
                onClick={this.onClick}
                aria-label={ariaLabel || defaultAriaLabel}
            />
        );
    }
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;