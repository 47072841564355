import classNames from 'classnames'
import React from 'react'
import styles from '../../scss/content.module.scss'
import { addTargetBlank } from '../../utils/addTargetBlankToLinks'

const RawHtmlScript = ({ html }) => {
  return (
    <div
      className={classNames(styles.content)}
      {...{
        dangerouslySetInnerHTML: { __html: addTargetBlank(html) }, // if html contains any anchor tag , add target as blank
      }}
    />
  )
}

export default RawHtmlScript
