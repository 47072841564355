import React from 'react'
import { HeroBox } from '..'

const ShowHeroBox = ({ article, location, id = "", tid2 = "", tokens }) => {
    let heroBox = null
    if (article.heroText) {
        heroBox = (
            <HeroBox
                tokens={tokens}
                article={article}
                location={location}
                site="wiki"
                id={id}
                tid2={tid2}
            />
        )
    } else if (article.category.category.slug === 'aptitude-tests') {
        heroBox = (
            <HeroBox
                tokens={tokens}
                article={{
                    heroText: 'Free Practice Aptitude Test',
                    heroButtonText: 'Take Test',
                    heroButtonUrl: 'https://tests.wikijob.co.uk/aptitude-tests-home',
                }}
                location={location}
                site="wiki"
                id={id}
                tid2={tid2}
            />
        )
    }
    return heroBox
}

export default ShowHeroBox