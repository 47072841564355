import React from 'react'
import classNames from 'classnames'
import styles from './HelloBar.module.scss'
import decorateURLWithTID from '../../utils/decorateUrlTid'

export default ({ location, url, buttonText, text }) => {
  return (
    <div role="alert" className={classNames(styles.helloBarBlockWrap)}>
      <div>
        {text}
        <a
          role="button"
          aria-pressed="true"
          data-name="helloBarBtn"
          href={decorateURLWithTID(url, location.pathname, 'hellobar')}
          className={classNames(styles.helloBarBlockButtonActive)}
          rel="sponsored noreferrer"
          target="_blank"
        >
          {buttonText}
        </a>
      </div>
    </div>
  )
}
