import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Button.module.scss'

const Button = ({ handleClick, value, isCorrect, isCurrentQuestion }) => {
  const classes = ['']
  // if (isCorrect !== null) {
  //   classes.push('bg-dark text-white')
  // }
  if (isCurrentQuestion) {
    classes.push('bg-dark text-white')
  }

  return (
    <button
      className={classNames(styles.pageLink, classes.join(' '))}
      onClick={handleClick}
      type="button"
    >
      {value}
    </button>
  )
}

Button.propTypes = {
  value: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default Button
