import React from 'react'
import { Helmet } from 'react-helmet'
import { Location } from '@reach/router'
import favicon16x16 from '../../images/favicon/favicon-16x16.png'
import favicon32x32 from '../../images/favicon/favicon-32x32.png'
import appleTouchIcon from '../../images/favicon/apple-touch-icon.png'
import { Script } from 'gatsby'

const PageHelmet = ({
  alternateUrl,
  title,
  description,
  keywords,
  image,
  url,
  children,
  article,
  disableAds,
  robots,
  lang,
}) => {
  const {
    updatedDate,
    createdDate,
    subcategory,
    author,
    publishedAt,
    firstPublishedAt,
  } = article || {}
  let imagePath =
    'https://www.datocms-assets.com/7756/1560847067-41auzq9ntel-us230.jpg'
  if (image) {
    imagePath = image
  }

  const getAuthorUrl = () => {
    if (!author) {
      return {}
    }
    return {
      url: `${process.env.GATSBY_SITE_BASE_URL}/team/${author
        .toLowerCase()
        .split(' ')
        .join('-')}`,
    }
  }

  const structuredArticleJSON = article
    ? `{
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": ${JSON.stringify(title)},
    "image": [
      ${JSON.stringify(imagePath)}
     ],
    "datePublished": "${firstPublishedAt}",
    "dateModified": "${publishedAt}",
    "author": [{
        "@type": "Person",
        "name": "${author || 'WikiJob'}",
        "url": "https://www.wikijob.co.uk${
          author ? `/team/${author?.toLowerCase()?.split(' ')?.join('-')}` : ''
        }"
      }]
  }`
    : ''

  return (
    <>
      <Location>
        {({ location }) => {
          return (
            <Helmet htmlAttributes={{ lang: lang || 'en' }}>
              {title && [
                <link
                  {...(alternateUrl?.length > 0 && {
                    rel: 'alternate',
                    hrefLang: `${lang || 'en'}`,
                    href: `${process.env.GATSBY_SITE_BASE_URL}${location.pathname}`,
                  })}
                />,

                <link
                  {...(alternateUrl?.length > 0 &&
                    lang != 'en' && {
                      rel: 'alternate',
                      hrefLang: 'x-default',
                      href: `${
                        alternateUrl.filter((item) => item.language === 'en')[0]
                          ?.alternateUrl // show url of main language , in this case its english
                      }`,
                    })}
                />,

                <link
                  {...(alternateUrl?.length > 0 &&
                    lang == 'en' && {
                      rel: 'alternate',
                      hrefLang: 'x-default',
                      href: `${process.env.GATSBY_SITE_BASE_URL}${location.pathname}`,
                    })}
                />,
                <title
                  {...{
                    key: 'title',
                    itemProp: 'name',
                  }}
                >
                  {title}
                </title>,
                <meta
                  {...{
                    key: 'ogTitle',
                    property: 'og:title',
                    content: title,
                  }}
                />,
                <meta
                  {...{
                    key: 'twitterTitle',
                    name: 'twitter:title',
                    content: title,
                  }}
                />,
                <meta
                  {...{
                    key: 'ogImage',
                    property: 'og:image',
                    content: imagePath,
                  }}
                />,
                <meta
                  {...{
                    key: 'ogImageWidth',
                    property: 'og:image:width',
                    content: 1200,
                  }}
                />,
                <meta
                  {...{
                    key: 'ogImageHeight',
                    property: 'og:image:height',
                    content: 630,
                  }}
                />,
                <meta
                  {...{
                    key: 'twitterImage',
                    name: 'twitter:image',
                    content: imagePath,
                  }}
                />,
                <meta
                  {...{
                    key: 'twitterCard',
                    name: 'twitter:card',
                    content: 'summary_large_image',
                  }}
                />,
                <meta
                  {...{
                    key: 'image',
                    itemProp: 'image',
                    content: imagePath,
                  }}
                />,
                <meta
                  {...{
                    key: 'name',
                    itemProp: 'name',
                    content: title,
                  }}
                />,
              ]}
              <meta name="article:published_time" content={publishedAt} />
              <meta property="article:modified_time" content={updatedDate} />
              <meta property="article:section" content={subcategory} />
              <meta name="article:author" content={author} />
              <meta property="og:type" content="article" />
              {url && <meta property="og:url" content={url} />}
              {url && <link rel="canonical" href={url} />}
              {keywords && <meta name="keywords" content={keywords} />}
              {robots && <meta name="robots" content={robots} />}
              {alternateUrl &&
                alternateUrl.map((item, i) => (
                  <link
                    key={i}
                    rel="alternate"
                    hrefLang={item.language}
                    href={item.alternateUrl}
                  />
                ))}
              <link
                {...{
                  rel: 'apple-touch-icon',
                  sizes: '180x180',
                  href: appleTouchIcon,
                }}
              />
              <link
                {...{
                  rel: 'icon',
                  type: 'image/png',
                  sizes: '32x32',
                  href: favicon32x32,
                }}
              />
              <link
                {...{
                  rel: 'icon',
                  type: 'image/png',
                  sizes: '16x16',
                  href: favicon16x16,
                }}
              />
              <meta
                httpEquiv="Content-Security-Policy"
                content="block-all-mixed-content"
              />
              {description && (
                <meta
                  {...{
                    key: 'description',
                    name: 'description',
                    content: description,
                  }}
                />
              )}
              {description && (
                <meta
                  {...{
                    key: 'ogDescription',
                    property: 'og:description',
                    content: description,
                  }}
                />
              )}
              <meta
                {...{
                  key: 'twitterDescription',
                  name: 'twitter:description',
                  content: description || 'WikiJob',
                }}
              />
              {disableAds && <meta name="disableAds" content />}
              {article && (
                <Script
                  defer
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{
                    __html: `
                ${JSON.stringify({
                  '@context': 'https://schema.org',
                  publisher: {
                    '@type': 'Organization',
                    name: 'WikiJob',
                    logo: {
                      '@type': 'ImageObject',
                      url: `${process.env.GATSBY_SITE_BASE_URL}/wikijob.png`,
                    },
                  },
                  '@type': 'Article',
                  headline: title,
                  description,
                  keywords: title,
                  author: {
                    '@type': 'Person',
                    name: `${author || 'The WikiJob Team'}`,
                    ...getAuthorUrl(),
                  },
                  mainEntityOfPage: url,
                  url,
                  datePublished: createdDate,
                  dateModified: updatedDate,
                  articleSection: subcategory,
                  image: [imagePath],
                })}
                `,
                  }}
                />
              )}
              {structuredArticleJSON && (
                <Script defer type="application/ld+json">
                  {structuredArticleJSON}
                </Script>
              )}

              {children && children}
            </Helmet>
          )
        }}
      </Location>
    </>
  )
}

export default PageHelmet
