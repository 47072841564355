import React from 'react'
import classNames from 'classnames'
import { AptitudeTestComp } from '../AptitudeTestComp'
import styles from '../../scss/content.module.scss'
import BlockQuote from './BlockQuote'
import CallToActionButton from './CallToActionButton'
import Image from './Image'
import Product from './Product/Product'
import ProsCons from './ProsCons'
import RawHtmlScript from './RawHtmlScript'
import Top10Items from './Top10Items'
import QuestionAnswer from './QuestionAnswer'
import { Row, Col } from '../../lib'
import Faqs from '../Faqs/Faqs'
import HandleHowToStep from './HandleHowToStep'
import HowToStepHeading from './HowToStepHeading'
import addTidLinks from '../../utils/addTidLinks'
import DoDont from './DoDont'
import replaceText from '../../utils/replaceText'
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import decorateUrlTid from '../../utils/decorateUrlTid'

const HandleRenderContent = ({
  element,
  count,
  pathname,
  location,
  article,
  tokens
}) => {
  if (!element || !element.internal) return null
  const cleanText = (text, counter) => {
    if (!text) return ''
    const formatedText = text
      .replace(/ /g, '-')
      .replace(/[^a-zA-Z0-9-]/g, '')
      .toLowerCase()

    if (!counter) return formatedText
    return `${counter}-${formatedText}`
  }
  switch (element.internal.type) {
    case 'DatoCmsComparisontable': {
      const { row, tableheadings } = element
      return (
        <table className="table table-responsive" style={{ height: '1px', width: "100%" }}>
          <tbody>
            {row.map((item, i) => {
              return (
                <>
                  <tr key={i} className="border-top-0" style={{ borderTop: 0 }}>
                    <td className={`${styles.tableDataPadding}`}>
                      <div className='h-100'>
                        <div className="th">{tableheadings[0]?.title}</div>
                        <td style={{padding: 0}}>
                          <a href={pathname ? decorateUrlTid(item.button[0].link, pathname, 'cta') : item.button[0].link}>
                            <RawHtmlScript
                              html={addTidLinks(replaceText(tokens, item.heading[0].markdownNode.childMarkdownRemark.html), pathname,)} // add Tid links as well as replace years
                            />
                          </a>

                        </td>
                      </div>
                    </td>

                    {item.content.map((col, pos) => {
                      return (
                        <td className={`${styles.tableDataPadding}`}>
                          <div className='h-100'>
                            <div  className="th">{tableheadings[pos + 1]?.title}</div>
                            {
                              col.internal?.type === 'DatoCmsHtml' ?
                                <RawHtmlScript html={col.html} /> : null
                            }
                          </div>
                        </td>
                      )
                    })}
                    <td className="col-cta table-data-padding" >
                      <CallToActionButton
                        item={item.button[0]}
                        pathname={pathname}
                      />
                    </td>

                  </tr>
                </>
              )
            })}
          </tbody>
        </table>
      )
    }
    case 'DatoCmsQuiz': {
      return (
        <Row className="mv-skip">
          <Col lg={12} className="position-static">
            <AptitudeTestComp
              article={article}
              data={element.test}
              location={location}
            />
          </Col>
        </Row>
      )
    }
    case 'DatoCmsFaqItem': {
      return (
        <Faqs
          answer={
            <RawHtmlScript
              html={replaceText(tokens, addTidLinks(element?.answerNode?.childMarkdownRemark.html, pathname))}
            />
          }
          question={replaceText(tokens, addTidLinks(element.question, pathname))}
        />
      )
    }
    case 'DatoCmsExampleQuestion':
      return (
        <QuestionAnswer
          questionHtml={replaceText(tokens, addTidLinks(element.questionNode.childMarkdownRemark.html, pathname))}
          answerHtml={replaceText(tokens, addTidLinks(element.answerExplanationNode.childMarkdownRemark.html, pathname))}
        />
      )
    case 'DatoCmsMarkdown':
      return (
        <RawHtmlScript
          html={addTidLinks(replaceText(tokens, element.markdownNode.childMarkdownRemark.html), pathname,)} // add Tid links as well as replace years
        />
      )
    case 'DatoCmsDoDont':
      return <DoDont html={element} />
    case 'DatoCmsProCon':
      return <ProsCons html={element} />
    case 'DatoCmsHtml':
      
      return <RawHtmlScript html={element.html} />
    case 'DatoCmsProduct':
      return <Product product={element} tokens={tokens} />
    case 'DatoCmsImage':
      return <Image image={element} tokens={tokens} />
    case 'DatoCmsRawHtmlScript':
      // Experimental , removing only video scripts
      if(element.rawHtml && element.rawHtml.includes('mv-video-target')) return <></>  
    return <RawHtmlScript html={element.rawHtml} />
    case 'DatoCmsCallToActionButton':
      return <CallToActionButton item={element} pathname={pathname} />
    case 'DatoCmsBigQuote':
      return (
        <BlockQuote
          html={replaceText(tokens, addTidLinks(element.bodyNode.childMarkdownRemark.html, pathname))}
          style={element.style}
        />
      )
    case 'DatoCmsTop10item':
      if (!element.published) {
        return null
      }
      count.val += 1
      return <Top10Items item={element} counter={count.val} tokens={tokens} />
    case 'DatoCmsHowto':
      let title = replaceText(tokens, element.title)
      return (
        <>
          <h2 id={cleanText(replaceText(tokens, title))}>{title}</h2>
          {element.intro.map((intro, i) => {
            return (
              <RawHtmlScript key={i}
                html={intro.markdownNode.childMarkdownRemark.html}
              />
            )
          })}
          {element.howtosteps.map((elem, i) => {
            return (
              <React.Fragment key={i}>
                <HowToStepHeading element={elem} />
                <Image image={elem} tokens={tokens} />
                {elem.body.map((ele, i) => {
                  return (
                    <div className={classNames(styles.content, '')} key={i}>
                      <HandleHowToStep tokens={tokens} element={ele} pathname={pathname} />
                    </div>
                  )
                })}
              </React.Fragment>
            )
          })}
        </>
      )

    case 'DatoCmsHowtostep':
      return (
        <>
          <HowToStepHeading element={element} />
          <Image image={element} tokens={tokens} />
          {element.body.map((ele, i) => {
            return (
              <div className={classNames(styles.content, '')} key={i}>
                <HandleHowToStep element={ele} pathname={pathname} />
              </div>
            )
          })}
        </>
      )

    case 'DatoCmsTradingChart':
      return (
        <>
          <div class="tradingview-widget-container">
            <AdvancedRealTimeChart
              symbol={element.symbol}
              locale={element.locale}
              autosize={element.autosize}
              interval={element.interval}
              timezone={element.timezone}
              theme={element.theme}
              style={element.style}
              enable_publishing={element.enable_publishing}
              allow_symbol_change={element.allow_symbol_change}
            >
            </AdvancedRealTimeChart>
          </div>
        </>
      )

    default:
      return null
  }
}

export default HandleRenderContent